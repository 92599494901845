/*
 *
 * EventsPage constants
 *
 */
export const REDUCER_KEY = 'EventsPage';
export const GET_EVENT_LIST = 'app/EventsPage/GET_EVENT_LIST';
export const GET_EVENT_LIST_ERROR = 'app/EventsPage/GET_EVENT_ERROR';
export const GET_EVENT_LIST_SUCCESS = 'app/EventsPage/GET_EVENT_SUCCESS';
export const RESET_GET_EVENT_LIST = 'app/EventsPage/RESET_GET_EVENT_LIST';
