/*
 *
 * VolunteerGroups reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createVolunteerGroups,
  createVolunteerGroupsError,
  createVolunteerGroupsSuccess,
  deleteVolunteerGroups,
  deleteVolunteerGroupsError,
  deleteVolunteerGroupsSuccess,
  getVolunteerGroupsList,
  getVolunteerGroupsListError,
  getVolunteerGroupsListSuccess,
  resetGetVolunteerGroupsList,
  setActiveNoteItem,
  updateVolunteerGroups,
  updateVolunteerGroupsError,
  updateVolunteerGroupsSuccess,
} from './actions';

import groupsEntityAdapter from './entity-adapter';

export const initialState = groupsEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  activeId: '',
  activeState: '',
  activeAction: 0,
  activeError: '',
  createState: 0,
  createError: '',
  entities: [],
});

/* eslint-disable default-case, no-param-reassign */
const volunteerGroupsSlice = createSlice({
  name: 'VolunteerGroups',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVolunteerGroupsList, (state, { payload }) => {
        state.loadingState = 1;
        state.error = '';
        if (payload.params && payload.params.page === 1) {
          groupsEntityAdapter.removeAll(state);
        }
      })
      .addCase(getVolunteerGroupsListError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getVolunteerGroupsListSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        groupsEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(setActiveNoteItem, (state, { payload }) => {
        const { id, actionName, actionState, actionError } = payload;
        state.activeAction = actionName;
        state.activeState = actionState;
        state.activeError = actionError;
        state.activeId = id;
      })
      .addCase(deleteVolunteerGroups, state => {
        state.activeState = 1;
        state.activeAction = 'Delete';
        state.activeError = '';
      })
      .addCase(deleteVolunteerGroupsError, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(deleteVolunteerGroupsSuccess, (state, { payload }) => {
        const { id } = payload;
        state.activeState = 2;
        groupsEntityAdapter.removeOne(state, id);
      })
      .addCase(updateVolunteerGroups, state => {
        state.activeState = 1;
        state.activeAction = 'Update';
        state.activeError = '';
      })
      .addCase(updateVolunteerGroupsError, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(updateVolunteerGroupsSuccess, (state, { payload }) => {
        const { id, name, updatedAt, description } = payload;
        state.activeState = 2;
        state.error = '';
        if (id && state.entities[id]) {
          groupsEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              description,
              updatedAt,
            },
          });
        }
      })
      .addCase(createVolunteerGroups, state => {
        state.createState = 1;
        state.createError = '';
      })
      .addCase(createVolunteerGroupsError, (state, { payload }) => {
        state.createState = 3;
        state.createError = payload;
      })
      .addCase(createVolunteerGroupsSuccess, (state, { payload }) => {
        state.createState = 2;
        groupsEntityAdapter.addOne(state, payload);
      })
      .addCase(resetGetVolunteerGroupsList, state => {
        state.loadingState = 0;
        state.error = '';
        groupsEntityAdapter.removeAll(state);
        state.totalPages = 0;
        state.count = 0;
        state.activeId = '';
        state.activeState = '';
        state.activeAction = 0;
        state.activeError = '';
        state.createState = 0;
        state.createError = '';
      });
  },
});

export default volunteerGroupsSlice.reducer;
