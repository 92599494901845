import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter({});

export const {
  selectById: selectUserById,
  selectIds: selectUserIds,
  selectAll: selectAllUser,
} = adapter.getSelectors();

export default adapter;
