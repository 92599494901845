/*
 *
 * SyncJobList reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  getJobListAct,
  getJobListErrorAct,
  getJobListSuccessAct,
  resetJobListAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  jobs: [],
};

/* eslint-disable default-case, no-param-reassign */
const syncJobListSlice = createSlice({
  name: 'SyncJobList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getJobListAct, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getJobListErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getJobListSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.count = payload.count;
        state.totalPages = payload.totalPages;
        state.jobs = payload.rows;
        state.totalPages = payload.totalPages;
      })
      .addCase(resetJobListAct, () => initialState);
  },
});

export default syncJobListSlice.reducer;
