import { takeLatest, takeEvery, call, put } from 'redux-saga/effects';

import {
  CREATE_VOLUNTEER_GROUPS,
  DELETE_VOLUNTEER_GROUPS,
  GET_VOLUNTEER_GROUPS_LIST,
  UPDATE_VOLUNTEER_GROUPS,
} from './constants';

import {
  createVolunteerGroupApi,
  deleteVolunteerGroupApi,
  getEventVolunteerGroupsApi,
  updateVolunteerGroupApi,
} from '../../api/event.api';

import {
  getVolunteerGroupsListError,
  getVolunteerGroupsListSuccess,
  deleteVolunteerGroupsError,
  deleteVolunteerGroupsSuccess,
  updateVolunteerGroupsSuccess,
  updateVolunteerGroupsError,
  createVolunteerGroupsError,
  createVolunteerGroupsSuccess,
} from './actions';
function* fetchVolunteerGroups({ payload }) {
  try {
    const { data } = yield call(getEventVolunteerGroupsApi, payload);
    if (data) {
      yield put(getVolunteerGroupsListSuccess(data));
    } else {
      yield put(getVolunteerGroupsListError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getVolunteerGroupsListError(errMessage));
  }
}

function* deleteVolunteerGroups({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteVolunteerGroupApi, payload);
    if (data) {
      yield put(deleteVolunteerGroupsSuccess({ id }));
    } else {
      yield put(deleteVolunteerGroupsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(deleteVolunteerGroupsError(errMessage));
  }
}

function* updateVolunteerGroups({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateVolunteerGroupApi, payload);
    if (data) {
      yield put(
        updateVolunteerGroupsSuccess({
          id,
          name: data.name,
          description: data.description,
          updatedAt: data.updatedAt,
        }),
      );
    } else {
      yield put(updateVolunteerGroupsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateVolunteerGroupsError(errMessage));
  }
}

function* createVolunteerGroups({ payload }) {
  try {
    const { data } = yield call(createVolunteerGroupApi, payload);
    if (data) {
      yield put(createVolunteerGroupsSuccess(data));
    } else {
      yield put(createVolunteerGroupsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(createVolunteerGroupsError(errMessage));
  }
}

// Individual exports for testing
export default function* volunteerGroupsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_VOLUNTEER_GROUPS_LIST, fetchVolunteerGroups);
  yield takeEvery(DELETE_VOLUNTEER_GROUPS, deleteVolunteerGroups);
  yield takeEvery(UPDATE_VOLUNTEER_GROUPS, updateVolunteerGroups);
  yield takeEvery(CREATE_VOLUNTEER_GROUPS, createVolunteerGroups);
}
