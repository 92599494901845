/*
 *
 * EventTeamList actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_EVENT_TEAM_LIST,
  GET_EVENT_TEAM_LIST_ERROR,
  GET_EVENT_TEAM_LIST_SUCCESS,
  GET_RESPONSIBLE_USERS,
  GET_RESPONSIBLE_USERS_ERROR,
  GET_RESPONSIBLE_USERS_SUCCESS,
  GET_TEAM_TAGS,
  GET_TEAM_TAGS_ERROR,
  GET_TEAM_TAGS_SUCCESS,
  GET_UNIQUE_COMPANY_NAMES,
  GET_UNIQUE_COMPANY_NAMES_ERROR,
  GET_UNIQUE_COMPANY_NAMES_SUCCESS,
  RESET_GET_EVENT_TEAM_LIST,
  UPDATE_MEMBER_DETAIL,
  UPDATE_MEMBER_DETAIL_ERROR,
  UPDATE_MEMBER_DETAIL_SUCCESS,
  UPDATE_TEAM_BOARDING_STATUS,
  UPDATE_TEAM_BOARDING_STATUS_ERROR,
  UPDATE_TEAM_BOARDING_STATUS_SUCCESS,
  UPDATE_TEAM_NOTE,
  UPDATE_TEAM_NOTE_ERROR,
  UPDATE_TEAM_NOTE_SUCCESS,
} from './constants';

export const getEventTeamList = createAction(GET_EVENT_TEAM_LIST);
export const getEventTeamListError = createAction(GET_EVENT_TEAM_LIST_ERROR);
export const getEventTeamListSuccess = createAction(
  GET_EVENT_TEAM_LIST_SUCCESS,
);

export const getResponsibleUsersAct = createAction(GET_RESPONSIBLE_USERS);
export const getResponsibleUsersErrorAct = createAction(
  GET_RESPONSIBLE_USERS_ERROR,
);
export const getResponsibleUsersSuccessAct = createAction(
  GET_RESPONSIBLE_USERS_SUCCESS,
);

export const getUniqueCompanyNamesAct = createAction(GET_UNIQUE_COMPANY_NAMES);
export const getUniqueCompanyNamesErrorAct = createAction(
  GET_UNIQUE_COMPANY_NAMES_ERROR,
);
export const getUniqueCompanyNamesSuccessAct = createAction(
  GET_UNIQUE_COMPANY_NAMES_SUCCESS,
);

export const getTeamTagsAct = createAction(GET_TEAM_TAGS);
export const getTeamTagsErrorAct = createAction(GET_TEAM_TAGS_ERROR);
export const getTeamTagsSuccessAct = createAction(GET_TEAM_TAGS_SUCCESS);

export const updateTeamBoardingStatusAct = createAction(
  UPDATE_TEAM_BOARDING_STATUS,
);
export const updateTeamBoardingStatusErrorAct = createAction(
  UPDATE_TEAM_BOARDING_STATUS_ERROR,
);
export const updateTeamBoardingStatusSuccessAct = createAction(
  UPDATE_TEAM_BOARDING_STATUS_SUCCESS,
);

export const updateMemberDetailAct = createAction(UPDATE_MEMBER_DETAIL);
export const updateMemberDetailErrorAct = createAction(
  UPDATE_MEMBER_DETAIL_ERROR,
);
export const updateMemberDetailSuccessAct = createAction(
  UPDATE_MEMBER_DETAIL_SUCCESS,
);

export const updateTeamNoteAct = createAction(UPDATE_TEAM_NOTE);
export const updateTeamNoteErrorAct = createAction(UPDATE_TEAM_NOTE_ERROR);
export const updateTeamNoteSuccessAct = createAction(UPDATE_TEAM_NOTE_SUCCESS);

export const resetGetEventTeamList = createAction(RESET_GET_EVENT_TEAM_LIST);
