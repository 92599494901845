/*
 *
 * EventTeamDetail constants
 *
 */
export const REDUCER_KEY = 'EventTeamDetail';
export const GET_TEAM_DETAIL = 'app/EventTeamDetail/GET_TEAM_DETAIL';
export const GET_TEAM_DETAIL_ERROR =
  'app/EventTeamDetail/GET_TEAM_DETAIL_ERROR';
export const GET_TEAM_DETAIL_SUCCESS =
  'app/EventTeamDetail/GET_TEAM_DETAIL_SUCCESS';
export const SET_TEAM_BIB_NUMBER = 'app/EventTeamDetail/SET_TEAM_BIB_NUMBER';
export const SET_TEAM_BIB_NUMBER_ERROR =
  'app/EventTeamDetail/SET_TEAM_BIB_NUMBER_ERROR';
export const SET_TEAM_BIB_NUMBER_SUCCESS =
  'app/EventTeamDetail/SET_TEAM_BIB_NUMBER_SUCCESS';
export const UPDATE_TAG_STATE = 'app/EventTeamDetail/UPDATE_TAG_STATE';
export const UPDATE_TAG_STATE_ERROR =
  'app/EventTeamDetail/UPDATE_TAG_STATE_ERROR';
export const UPDATE_TAG_STATE_SUCCESS =
  'app/EventTeamDetail/UPDATE_TAG_STATE_SUCCESS';

export const UPDATE_START_TIME = 'app/EventTeamDetail/UPDATE_START_TIME';
export const UPDATE_START_TIME_ERROR =
  'app/EventTeamDetail/UPDATE_START_TIME_ERROR';
export const UPDATE_START_TIME_SUCCESS =
  'app/EventTeamDetail/UPDATE_START_TIME_SUCCESS';

export const UPDATE_TEAM_STATUS = 'app/EventTeamDetail/UPDATE_TEAM_STATUS';
export const UPDATE_TEAM_STATUS_ERROR =
  'app/EventTeamDetail/UPDATE_TEAM_STATUS_ERROR';
export const UPDATE_TEAM_STATUS_SUCCESS =
  'app/EventTeamDetail/UPDATE_TEAM_STATUS_SUCCESS';

export const UPDATE_MEDICAL_CERT = 'app/EventTeamDetail/UPDATE_MEDICAL_CERT';
export const UPDATE_MEDICAL_CERT_ERROR =
  'app/EventTeamDetail/UPDATE_MEDICAL_CERT_ERROR';
export const UPDATE_MEDICAL_CERT_SUCCESS =
  'app/EventTeamDetail/UPDATE_MEDICAL_CERT_SUCCESS';

export const UPDATE_PARENTAL_CONSENT =
  'app/EventTeamDetail/UPDATE_PARENTAL_CONSENT';
export const UPDATE_PARENTAL_CONSENT_ERROR =
  'app/EventTeamDetail/UPDATE_PARENTAL_CONSENT_ERROR';
export const UPDATE_PARENTAL_CONSENT_SUCCESS =
  'app/EventTeamDetail/UPDATE_PARENTAL_CONSENT_SUCCESS';

export const UPDATE_WELCOME_STATUS =
  'app/EventTeamDetail/UPDATE_WELCOME_STATUS';
export const UPDATE_WELCOME_STATUS_ERROR =
  'app/EventTeamDetail/UPDATE_WELCOME_STATUS_ERROR';
export const UPDATE_WELCOME_STATUS_SUCCESS =
  'app/EventTeamDetail/UPDATE_WELCOME_STATUS_SUCCESS';

export const ASSIGN_USER = 'app/EventTeamDetail/ASSIGN_USER';
export const ASSIGN_USER_ERROR = 'app/EventTeamDetail/ASSIGN_USER_ERROR';
export const ASSIGN_USER_SUCCESS = 'app/EventTeamDetail/ASSIGN_USER_SUCCESS';

export const RESET_GET_TEAM_DETAIL =
  'app/EventTeamDetail/RESET_GET_TEAM_DETAIL';
