import { call, put, takeLatest } from 'redux-saga/effects';
import { GET_JOB_LIST } from './constants';
import { getJobListErrorAct, getJobListSuccessAct } from './actions';
import { getErrorMessage } from '../../utils/error.util';
import { getJobsApi } from '../../api/admin.api';

function* fetchJobs({ payload }) {
  try {
    const { data } = yield call(getJobsApi, payload);
    if (data) {
      yield put(getJobListSuccessAct(data));
    } else {
      throw new Error('Something went wrong');
    }
  } catch (error) {
    const errMessage = getErrorMessage(error);
    yield put(getJobListErrorAct(errMessage));
  }
}
// Individual exports for testing
export default function* syncJobListSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_JOB_LIST, fetchJobs);
}
