/*
 *
 * EventsPage reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import {
  getEventListAction,
  getEventListActionError,
  getEventListActionSuccess,
  resetEventListAction,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  events: [],
  totalPages: 0,
};

/* eslint-disable default-case, no-param-reassign */
const eventsPageSlice = createSlice({
  name: 'EventsPage',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getEventListAction, state => {
      state.loadingState = 1;
      state.error = '';
    });
    builder.addCase(getEventListActionError, (state, { payload }) => {
      state.loadingState = 3;
      state.error = payload;
    });
    builder.addCase(getEventListActionSuccess, (state, { payload }) => {
      state.loadingState = 2;
      state.error = '';
      state.events = payload.rows;
      state.totalPages = payload.totalPages;
    });
    builder.addCase(resetEventListAction, state => {
      state.loadingState = 0;
      state.error = '';
      state.events = [];
      state.totalPages = 0;
    });
  },
});

export default eventsPageSlice.reducer;
