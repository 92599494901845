/*
 *
 * SyncJobList constants
 *
 */
export const REDUCER_KEY = 'SyncJobList';
export const GET_JOB_LIST = 'app/SyncJobList/GET_JOB_LIST';
export const GET_JOB_LIST_SUCCESS = 'app/SyncJobList/GET_JOB_LIST_SUCCESS';
export const GET_JOB_LIST_ERROR = 'app/SyncJobList/GET_JOB_LIST_ERROR';

export const RESET_JOB_LIST = 'app/SyncJobList/RESET_JOB_LIST';
