import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  GET_EVENT_TEAM_LIST,
  GET_RESPONSIBLE_USERS,
  GET_TEAM_TAGS,
  GET_UNIQUE_COMPANY_NAMES,
  UPDATE_MEMBER_DETAIL,
  UPDATE_TEAM_BOARDING_STATUS,
  UPDATE_TEAM_NOTE,
} from './constants';
import { getEventTeamTagsApi, getEventTeamsApi } from '../../api/event.api';
import {
  getEventTeamListError,
  getEventTeamListSuccess,
  getResponsibleUsersErrorAct,
  getResponsibleUsersSuccessAct,
  getTeamTagsErrorAct,
  getTeamTagsSuccessAct,
  getUniqueCompanyNamesErrorAct,
  getUniqueCompanyNamesSuccessAct,
  updateMemberDetailErrorAct,
  updateMemberDetailSuccessAct,
  updateTeamBoardingStatusErrorAct,
  updateTeamBoardingStatusSuccessAct,
  updateTeamNoteErrorAct,
  updateTeamNoteSuccessAct,
} from './actions';
import { getUsersAssignedToTeam } from '../../api/user.api';
import {
  getUniqueCompanyNamesApi,
  updateBoardingStatusApi,
  updateNoteApi,
} from '../../api/team.api';

import { updateMemberApi } from '../../api/member.api';

function* fetchEventTeams({ payload }) {
  try {
    const { data } = yield call(getEventTeamsApi, payload.id, payload.params);
    if (data) {
      yield put(getEventTeamListSuccess(data));
    } else {
      yield put(getEventTeamListError({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getEventTeamListError(errMessage));
  }
}

function* fetchResponsibleUsers({ payload }) {
  try {
    const { data } = yield call(getUsersAssignedToTeam, payload.id);
    if (data) {
      yield put(getResponsibleUsersSuccessAct(data));
    } else {
      yield put(getResponsibleUsersErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getResponsibleUsersErrorAct(errMessage));
  }
}

function* fetchUniqueCompanyNames({ payload }) {
  try {
    const { data } = yield call(getUniqueCompanyNamesApi, payload.id);
    if (data) {
      yield put(getUniqueCompanyNamesSuccessAct(data));
    } else {
      yield put(getUniqueCompanyNamesErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getUniqueCompanyNamesErrorAct(errMessage));
  }
}

function* fetchTags({ payload }) {
  try {
    const { data } = yield call(getEventTeamTagsApi, payload);
    if (data) {
      yield put(getTeamTagsSuccessAct(data));
    } else {
      yield put(getTeamTagsErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getTeamTagsErrorAct(errMessage));
  }
}

function* updateTeamBoardingStatus({ payload }) {
  try {
    const { data } = yield call(updateBoardingStatusApi, payload);
    if (data) {
      yield put(updateTeamBoardingStatusSuccessAct(data));
    } else {
      yield put(
        updateTeamBoardingStatusErrorAct({
          id: payload.id,
          boardingStatus: payload.data.boardingStatus,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateTeamBoardingStatusErrorAct({
        id: payload.id,
        boardingStatus: payload.data.boardingStatus,
        error: errMessage,
      }),
    );
  }
}

function* updateMemberDetail({ payload }) {
  try {
    const { data } = yield call(updateMemberApi, payload);
    if (data) {
      yield put(
        updateMemberDetailSuccessAct({
          ...payload,
          data,
        }),
      );
    } else {
      yield put(
        updateMemberDetailErrorAct({
          ...payload,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateMemberDetailErrorAct({
        ...payload,
        error: errMessage,
      }),
    );
  }
}

function* updateTeamNote({ payload }) {
  try {
    const { data } = yield call(updateNoteApi, payload);
    if (data) {
      yield put(updateTeamNoteSuccessAct(data));
    } else {
      yield put(
        updateTeamNoteErrorAct({
          ...payload,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateTeamNoteErrorAct({
        ...payload,
        error: errMessage,
      }),
    );
  }
}

// Individual exports for testing
export default function* eventTeamListSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_EVENT_TEAM_LIST, fetchEventTeams);
  yield takeLatest(GET_RESPONSIBLE_USERS, fetchResponsibleUsers);
  yield takeLatest(GET_UNIQUE_COMPANY_NAMES, fetchUniqueCompanyNames);
  yield takeLatest(GET_TEAM_TAGS, fetchTags);
  yield takeEvery(UPDATE_TEAM_BOARDING_STATUS, updateTeamBoardingStatus);
  yield takeEvery(UPDATE_MEMBER_DETAIL, updateMemberDetail);
  yield takeEvery(UPDATE_TEAM_NOTE, updateTeamNote);
}
