/*
 *
 * InternalUser actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_INTERNAL_USER,
  GET_INTERNAL_USER_ERROR,
  GET_INTERNAL_USER_SUCCESS,
  RESET_GET_INTERNAL_USER,
  GET_INTERNAL_USERS,
  GET_INTERNAL_USERS_ERROR,
  GET_INTERNAL_USERS_SUCCESS,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_ERROR,
  CHANGE_PASSWORD_SUCCESS,
} from './constants';

export const getInternalUser = createAction(GET_INTERNAL_USER);
export const getInternalUserError = createAction(GET_INTERNAL_USER_ERROR);
export const getInternalUserSuccess = createAction(GET_INTERNAL_USER_SUCCESS);

export const getInternalUsers = createAction(GET_INTERNAL_USERS);
export const getInternalUsersError = createAction(GET_INTERNAL_USERS_ERROR);
export const getInternalUsersSuccess = createAction(GET_INTERNAL_USERS_SUCCESS);

export const changePasswordAct = createAction(CHANGE_PASSWORD);
export const changePasswordErrorAct = createAction(CHANGE_PASSWORD_ERROR);
export const changePasswordSuccessAct = createAction(CHANGE_PASSWORD_SUCCESS);

export const resetGetInternalUser = createAction(RESET_GET_INTERNAL_USER);
