import { call, put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  DELETE_SUPPORTER,
  GET_SUPPORTER_DETAIL,
  REMOVE_MEAL,
  TRANSFER_MEAL,
  UPDATE_SUPPORTER,
} from './constants';
import {
  deleteMealApi,
  deleteSupporterApi,
  getSupporterDetailApi,
  transferMealApi,
  updateSupporterDetailApi,
} from '../../api/supporter.api';
import {
  deleteSupporterErrorAct,
  deleteSupporterSuccessAct,
  getSupporterDetailErrorAct,
  getSupporterDetailSuccessAct,
  removeMealErrorAct,
  removeMealSuccessAct,
  transferMealErrorAct,
  transferMealSuccessAct,
  updateSupporterErrorAct,
  updateSupporterSuccessAct,
} from './actions';

function* fetchSupporterDetail({ payload }) {
  try {
    const { data } = yield call(getSupporterDetailApi, payload);
    if (data) {
      yield put(getSupporterDetailSuccessAct(data));
    } else {
      yield put(
        getSupporterDetailErrorAct({ message: 'Something went wrong' }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getSupporterDetailErrorAct(errMessage));
  }
}

function* updateSupporter({ payload }) {
  try {
    const { data } = yield call(updateSupporterDetailApi, payload);
    if (data) {
      yield put(updateSupporterSuccessAct(data));
    } else {
      yield put(updateSupporterErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateSupporterErrorAct(errMessage));
  }
}

function* transferMeal({ payload }) {
  try {
    const { data } = yield call(transferMealApi, payload);
    if (data) {
      yield put(transferMealSuccessAct(data));
    } else {
      yield put(
        transferMealErrorAct({
          error: 'Something went wrong',
          mealId: payload.data.mealId,
          dayId: payload.dayId,
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      transferMealErrorAct({
        error: errMessage,
        mealId: payload.data.mealId,
        dayId: payload.dayId,
      }),
    );
  }
}

function* deleteMeal({ payload }) {
  const { supporterId, data: payloadData, dayId } = payload;
  const { mealId } = payloadData;
  try {
    const { data } = yield call(deleteMealApi, supporterId, mealId);
    if (data) {
      yield put(
        removeMealSuccessAct({
          id: mealId,
          day: dayId,
        }),
      );
    } else {
      yield put(
        removeMealErrorAct({
          error: 'Something went wrong',
          mealId,
          dayId,
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      removeMealErrorAct({
        error: errMessage,
        mealId,
        dayId,
      }),
    );
  }
}

function* deleteSupporter({ payload }) {
  try {
    const { data } = yield call(deleteSupporterApi, payload);
    if (data) {
      yield put(deleteSupporterSuccessAct(data));
    } else {
      yield put(deleteSupporterErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(deleteSupporterErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* supporterDetailSaga() {
  yield takeLatest(GET_SUPPORTER_DETAIL, fetchSupporterDetail);
  yield takeEvery(UPDATE_SUPPORTER, updateSupporter);
  yield takeLatest(DELETE_SUPPORTER, deleteSupporter);
  yield takeLatest(TRANSFER_MEAL, transferMeal);
  yield takeLatest(REMOVE_MEAL, deleteMeal);
}
