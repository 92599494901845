import { call, put, takeLatest } from 'redux-saga/effects';

import {
  CHANGE_PASSWORD,
  GET_INTERNAL_USER,
  GET_INTERNAL_USERS,
} from './constants';
import {
  getUserDetailApi,
  getUsersApi,
  updatePasswordApi,
} from '../../api/user.api';
import {
  changePasswordErrorAct,
  changePasswordSuccessAct,
  getInternalUserError,
  getInternalUsersError,
  getInternalUsersSuccess,
  getInternalUserSuccess,
} from './actions';

function* getUserDetail({ payload }) {
  try {
    const { data } = yield call(getUserDetailApi, payload);
    if (data) {
      yield put(getInternalUserSuccess(data));
    } else {
      yield put(getInternalUserError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getInternalUserError(errMessage));
  }
}

function* getUsers({ payload }) {
  try {
    const { data } = yield call(getUsersApi, payload);
    if (data) {
      yield put(getInternalUsersSuccess(data));
    } else {
      yield put(getInternalUsersError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getInternalUsersError(errMessage));
  }
}

function* updateChangePassword({ payload }) {
  try {
    const { data } = yield call(updatePasswordApi, payload);
    if (data) {
      yield put(changePasswordSuccessAct());
    } else {
      yield put(changePasswordErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(changePasswordErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* internalUserSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_INTERNAL_USER, getUserDetail);
  yield takeLatest(GET_INTERNAL_USERS, getUsers);
  yield takeLatest(CHANGE_PASSWORD, updateChangePassword);
}
