/*
 *
 * EventVolunteerList reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  getEventVolunteerList,
  getEventVolunteerListError,
  getEventVolunteerListSuccess,
  getResponsibleUsers,
  getResponsibleUsersError,
  getResponsibleUsersSuccess,
  getUniqueZipCodesAct,
  getUniqueZipCodesActError,
  getUniqueZipCodesActSuccess,
  getVolunteerMissions,
  getVolunteerMissionsError,
  getVolunteerMissionsSuccess,
  getVolunteerTags,
  getVolunteerTagsError,
  getVolunteerTagsSuccess,
  resetGetEventVolunteerList,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  volunteers: [],
  totalPages: 0,
  missions: [],
  missionLoadingState: 0,
  missionError: '',
  count: 0,
  responsibleUsers: [],
  responsibleUsersLoadingState: 0,
  responsibleUsersError: '',
  tags: [],
  tagLoadingState: 0,
  tagsError: '',
  zipCodesState: 0,
  zipCodes: [],
};

/* eslint-disable default-case, no-param-reassign */
const eventVolunteerListSlice = createSlice({
  name: 'EventVolunteerList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventVolunteerList, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getEventVolunteerListError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getEventVolunteerListSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.volunteers = payload.rows;
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(getVolunteerMissions, state => {
        state.missionLoadingState = 1;
        state.missionError = '';
      })
      .addCase(getVolunteerMissionsError, (state, { payload }) => {
        state.missionLoadingState = 3;
        state.missionError = payload;
      })
      .addCase(getVolunteerMissionsSuccess, (state, { payload }) => {
        state.missionLoadingState = 2;
        state.missionError = '';
        state.missions = payload;
      })
      .addCase(getResponsibleUsers, state => {
        state.responsibleUsersLoadingState = 1;
        state.responsibleUsersError = '';
      })
      .addCase(getResponsibleUsersError, (state, { payload }) => {
        state.responsibleUsersLoadingState = 3;
        state.responsibleUsersError = payload;
      })
      .addCase(getResponsibleUsersSuccess, (state, { payload }) => {
        state.responsibleUsersLoadingState = 2;
        state.responsibleUsersError = '';
        state.responsibleUsers = payload;
      })
      .addCase(getVolunteerTags, state => {
        state.tagLoadingState = 1;
        state.tagsError = '';
      })
      .addCase(getVolunteerTagsError, (state, { payload }) => {
        state.tagLoadingState = 3;
        state.tagsError = payload;
      })
      .addCase(getVolunteerTagsSuccess, (state, { payload }) => {
        state.tagLoadingState = 2;
        state.tagsError = '';
        state.tags = payload.rows;
      })
      .addCase(getUniqueZipCodesAct, state => {
        state.error = '';
        state.zipCodesState = 1;
      })
      .addCase(getUniqueZipCodesActError, (state, { payload }) => {
        state.error = payload;
        state.zipCodesState = 3;
      })
      .addCase(getUniqueZipCodesActSuccess, (state, { payload }) => {
        state.zipCodesState = 2;
        state.error = '';
        state.zipCodes = payload;
      })
      .addCase(resetGetEventVolunteerList, state => {
        state.loadingState = 0;
        state.error = '';
        state.volunteers = [];
        state.totalPages = 0;
        state.count = 0;
        state.missionLoadingState = 0;
        state.missionError = '';
        state.missions = [];
        state.responsibleUsersLoadingState = 0;
        state.responsibleUsersError = '';
        state.responsibleUsers = [];
        state.tagLoadingState = 0;
        state.tagsError = '';
        state.tags = [];
        state.zipCodesState = '';
        state.zipCodes = [];
      });
  },
});

export default eventVolunteerListSlice.reducer;
