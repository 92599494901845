/*
 *
 * EventMemberList actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_EVENT_MEMBER_LIST,
  GET_EVENT_MEMBER_LIST_ERROR,
  GET_EVENT_MEMBER_LIST_SUCCESS,
  GET_MEMBER_TAGS,
  GET_MEMBER_TAGS_ERROR,
  GET_MEMBER_TAGS_SUCCESS,
  GET_UNIQUE_COMPANY,
  GET_UNIQUE_COMPANY_ERROR,
  GET_UNIQUE_COMPANY_SUCCESS,
  GET_UNIQUE_COUNTRIES,
  GET_UNIQUE_COUNTRIES_ERROR,
  GET_UNIQUE_COUNTRIES_SUCCESS,
  GET_UNIQUE_SOURCE,
  GET_UNIQUE_SOURCE_ERROR,
  GET_UNIQUE_SOURCE_SUCCESS,
  GET_UNIQUE_ZIPCODES,
  GET_UNIQUE_ZIPCODES_ERROR,
  GET_UNIQUE_ZIPCODES_SUCCESS,
  RESET_GET_EVENT_MEMBER_LIST,
  UPDATE_MEMBER_DETAIL,
  UPDATE_MEMBER_DETAIL_ERROR,
  UPDATE_MEMBER_DETAIL_SUCCESS,
} from './constants';

export const getEventMemberListAct = createAction(GET_EVENT_MEMBER_LIST);
export const getEventMemberListErrorAct = createAction(
  GET_EVENT_MEMBER_LIST_ERROR,
);
export const getEventMemberListSuccessAct = createAction(
  GET_EVENT_MEMBER_LIST_SUCCESS,
);

export const getUniqueZipCodesAct = createAction(GET_UNIQUE_ZIPCODES);
export const getUniqueZipCodesErrorAct = createAction(
  GET_UNIQUE_ZIPCODES_ERROR,
);
export const getUniqueZipCodesSuccessAct = createAction(
  GET_UNIQUE_ZIPCODES_SUCCESS,
);

export const getUniqueCountriesAct = createAction(GET_UNIQUE_COUNTRIES);
export const getUniqueCountriesErrorAct = createAction(
  GET_UNIQUE_COUNTRIES_ERROR,
);
export const getUniqueCountriesSuccessAct = createAction(
  GET_UNIQUE_COUNTRIES_SUCCESS,
);

export const getUniqueSourceAct = createAction(GET_UNIQUE_SOURCE);
export const getUniqueSourceErrorAct = createAction(GET_UNIQUE_SOURCE_ERROR);
export const getUniqueSourceSuccessAct = createAction(
  GET_UNIQUE_SOURCE_SUCCESS,
);

export const getUniqueCompaniesAct = createAction(GET_UNIQUE_COMPANY);
export const getUniqueCompaniesErrorAct = createAction(
  GET_UNIQUE_COMPANY_ERROR,
);
export const getUniqueCompaniesSuccessAct = createAction(
  GET_UNIQUE_COMPANY_SUCCESS,
);

export const getMemberTagsAct = createAction(GET_MEMBER_TAGS);
export const getMemberTagsErrorAct = createAction(GET_MEMBER_TAGS_ERROR);
export const getMemberTagsSuccessAct = createAction(GET_MEMBER_TAGS_SUCCESS);

export const updateMemberDetailAct = createAction(UPDATE_MEMBER_DETAIL);
export const updateMemberDetailErrorAct = createAction(
  UPDATE_MEMBER_DETAIL_ERROR,
);
export const updateMemberDetailSuccessAct = createAction(
  UPDATE_MEMBER_DETAIL_SUCCESS,
);

export const resetMemberListAct = createAction(RESET_GET_EVENT_MEMBER_LIST);
