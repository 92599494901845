import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  GET_EVENT_MEMBER_LIST,
  GET_MEMBER_TAGS,
  GET_UNIQUE_COMPANY,
  GET_UNIQUE_COUNTRIES,
  GET_UNIQUE_SOURCE,
  GET_UNIQUE_ZIPCODES,
  UPDATE_MEMBER_DETAIL,
} from './constants';
import {
  getEventMemberListErrorAct,
  getEventMemberListSuccessAct,
  getMemberTagsErrorAct,
  getMemberTagsSuccessAct,
  getUniqueCompaniesErrorAct,
  getUniqueCompaniesSuccessAct,
  getUniqueCountriesErrorAct,
  getUniqueCountriesSuccessAct,
  getUniqueSourceErrorAct,
  getUniqueSourceSuccessAct,
  getUniqueZipCodesErrorAct,
  getUniqueZipCodesSuccessAct,
  updateMemberDetailErrorAct,
  updateMemberDetailSuccessAct,
} from './actions';
import {
  getEventMemberTagsApi,
  getEventMembersApi,
  getMemberUniqueCountriesApi,
  getMemberUniqueSourcesApi,
  getMemberUniqueZipCodesApi,
  getTeamUniqueCompaniesApi,
} from '../../api/event.api';
import { updateMemberApi } from '../../api/member.api';

function* fetchEventMembers({ payload }) {
  try {
    const { data } = yield call(getEventMembersApi, payload.id, payload.params);
    if (data) {
      yield put(getEventMemberListSuccessAct(data));
    } else {
      yield put(getEventMemberListErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getEventMemberListErrorAct(errMessage));
  }
}

function* fetchTags({ payload }) {
  try {
    const { data } = yield call(getEventMemberTagsApi, payload);
    if (data) {
      yield put(getMemberTagsSuccessAct(data));
    } else {
      yield put(getMemberTagsErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getMemberTagsErrorAct(errMessage));
  }
}

function* fetchUniqueZipCodes({ payload }) {
  try {
    const { data } = yield call(getMemberUniqueZipCodesApi, payload.id);
    if (data) {
      yield put(getUniqueZipCodesSuccessAct(data));
    } else {
      yield put(getUniqueZipCodesErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getUniqueZipCodesErrorAct(errMessage));
  }
}

function* fetchUniqueCompanies({ payload }) {
  try {
    const { data } = yield call(getTeamUniqueCompaniesApi, payload.id);
    if (data) {
      yield put(getUniqueCompaniesSuccessAct(data));
    } else {
      yield put(getUniqueCompaniesErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getUniqueCompaniesErrorAct(errMessage));
  }
}

function* fetchUniqueSources({ payload }) {
  try {
    const { data } = yield call(getMemberUniqueSourcesApi, payload.id);
    if (data) {
      yield put(getUniqueSourceSuccessAct(data));
    } else {
      yield put(getUniqueSourceErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getUniqueSourceErrorAct(errMessage));
  }
}

function* fetchUniqueCountries({ payload }) {
  try {
    const { data } = yield call(getMemberUniqueCountriesApi, payload.id);
    if (data) {
      yield put(getUniqueCountriesSuccessAct(data));
    } else {
      yield put(getUniqueCountriesErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getUniqueCountriesErrorAct(errMessage));
  }
}

function* updateMemberDetail({ payload }) {
  try {
    const { data } = yield call(updateMemberApi, payload);
    if (data) {
      yield put(updateMemberDetailSuccessAct(data));
    } else {
      yield put(
        updateMemberDetailErrorAct({
          ...payload,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateMemberDetailErrorAct({
        ...payload,
        error: errMessage,
      }),
    );
  }
}

// Individual exports for testing
export default function* eventMemberListSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_EVENT_MEMBER_LIST, fetchEventMembers);
  yield takeLatest(GET_UNIQUE_ZIPCODES, fetchUniqueZipCodes);
  yield takeLatest(GET_UNIQUE_COMPANY, fetchUniqueCompanies);
  yield takeLatest(GET_UNIQUE_COUNTRIES, fetchUniqueCountries);
  yield takeLatest(GET_UNIQUE_SOURCE, fetchUniqueSources);
  yield takeLatest(GET_MEMBER_TAGS, fetchTags);
  yield takeEvery(UPDATE_MEMBER_DETAIL, updateMemberDetail);
}
