import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';
import {
  assignGroupError,
  assignGroupSuccess,
  assignUserError,
  assignUserSuccess,
  deleteVolunteerErrorAct,
  deleteVolunteerSuccessAct,
  getVolunteerDetailError,
  getVolunteerDetailSuccess,
  updateLinkedVolunteerError,
  updateLinkedVolunteerSuccess,
  updateMissionStateError,
  updateMissionStateSuccess,
  updateParentalConsentStateError,
  updateParentalConsentStateSuccess,
  updateTagStateError,
  updateTagStateSuccess,
  updateVerifyLicenseStateError,
  updateVerifyLicenseStateSuccess,
  updateVerifyStateError,
  updateVerifyStateSuccess,
  updateVolunteerError,
  updateVolunteerSuccess,
} from './actions';
import {
  assignGroup,
  getVolunteerDetail,
  updateLicense,
  updateMission,
  updateTag,
  verifyVolunteer,
  assignUser,
  updateVolunteerDetail,
  updateParentalConsent,
  linkVolunteerApi,
  deleteVolunteerApi,
} from '../../api/volunteer.api';
import {
  ASSIGN_GROUP,
  ASSIGN_USER,
  DELETE_VOLUNTEER,
  GET_VOLUNTEER_DETAIL,
  UPDATE_LINKED_VOLUNTEER,
  UPDATE_MISSION_STATE,
  UPDATE_PARENTAL_CONSENT_STATE,
  UPDATE_TAG_STATE,
  UPDATE_VERIFY_LICENSE_STATE,
  UPDATE_VERIFY_STATE,
  UPDATE_VOLUNTEER,
} from './constants';

// import { DEFAULT_ACTION } from './constants';

function* fetchVolunteerDetail({ payload }) {
  try {
    const { data } = yield call(getVolunteerDetail, payload);
    if (data) {
      yield put(getVolunteerDetailSuccess(data));
    } else {
      yield put(getVolunteerDetailError({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getVolunteerDetailError(errMessage));
  }
}

function* updateMissionState({ payload }) {
  const { data: payloadData } = payload;
  try {
    const { data } = yield call(updateMission, payload);
    if (data) {
      yield put(updateMissionStateSuccess(data));
    } else {
      yield put(
        updateMissionStateError({
          missionId: payloadData.missionId,
          isAssigned: !payloadData.isAssigned,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateMissionStateError({
        missionId: payloadData.missionId,
        isAssigned: !payloadData.isAssigned,
        error: errMessage,
      }),
    );
  }
}

function* updateTagState({ payload }) {
  const { data: payloadData } = payload;
  try {
    const { data } = yield call(updateTag, payload);
    if (data) {
      yield put(updateTagStateSuccess(data));
    } else {
      yield put(
        updateTagStateError({
          tagId: payloadData.tagId,
          active: !payloadData.active,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      updateTagStateError({
        tagId: payloadData.tagId,
        active: !payloadData.active,
        error: errMessage,
      }),
    );
  }
}

function* updateAssignedUser({ payload }) {
  try {
    const { data } = yield call(assignUser, payload);
    if (data) {
      yield put(assignUserSuccess(data));
    } else {
      yield put(assignUserError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(assignUserError(errMessage));
  }
}

function* updateAssignedGroup({ payload }) {
  try {
    const { data } = yield call(assignGroup, payload);
    if (data) {
      yield put(assignGroupSuccess(data));
    } else {
      yield put(assignGroupError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(assignGroupError(errMessage));
  }
}

function* updateLicenseVerifyState({ payload }) {
  try {
    const { data } = yield call(updateLicense, payload);
    if (data) {
      yield put(updateVerifyLicenseStateSuccess(data));
    } else {
      yield put(updateVerifyLicenseStateError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateVerifyLicenseStateError(errMessage));
  }
}

function* updateParentalConsentState({ payload }) {
  try {
    const { data } = yield call(updateParentalConsent, payload);
    if (data) {
      yield put(updateParentalConsentStateSuccess(data));
    } else {
      yield put(updateParentalConsentStateError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateParentalConsentStateError(errMessage));
  }
}

function* updateVolunteerVerifyState({ payload }) {
  try {
    const { data } = yield call(verifyVolunteer, payload);
    if (data) {
      yield put(updateVerifyStateSuccess(data));
    } else {
      yield put(updateVerifyStateError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateVerifyStateError(errMessage));
  }
}

function* updateVolunteer({ payload }) {
  try {
    const { data } = yield call(updateVolunteerDetail, payload);
    if (data) {
      yield put(updateVolunteerSuccess(data));
    } else {
      yield put(updateVolunteerError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateVolunteerError(errMessage));
  }
}

function* linkVolunteer({ payload }) {
  try {
    const { data } = yield call(linkVolunteerApi, payload);
    if (data) {
      yield put(updateLinkedVolunteerSuccess(data));
    } else {
      yield put(updateLinkedVolunteerError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateLinkedVolunteerError(errMessage));
  }
}

function* deleteVolunteer({ payload }) {
  try {
    const { data } = yield call(deleteVolunteerApi, payload);
    if (data) {
      yield put(deleteVolunteerSuccessAct(data));
    } else {
      yield put(deleteVolunteerErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(deleteVolunteerErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* volunteerDetailSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_VOLUNTEER_DETAIL, fetchVolunteerDetail);
  yield takeEvery(UPDATE_MISSION_STATE, updateMissionState);
  yield takeEvery(UPDATE_TAG_STATE, updateTagState);
  yield takeEvery(ASSIGN_USER, updateAssignedUser);
  yield takeEvery(ASSIGN_GROUP, updateAssignedGroup);
  yield takeEvery(UPDATE_VERIFY_LICENSE_STATE, updateLicenseVerifyState);
  yield takeEvery(UPDATE_VERIFY_STATE, updateVolunteerVerifyState);
  yield takeEvery(UPDATE_VOLUNTEER, updateVolunteer);
  yield takeEvery(UPDATE_PARENTAL_CONSENT_STATE, updateParentalConsentState);
  yield takeEvery(UPDATE_LINKED_VOLUNTEER, linkVolunteer);
  yield takeLatest(DELETE_VOLUNTEER, deleteVolunteer);
}
