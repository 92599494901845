import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter({});

export const {
  selectById: selectMemberById,
  selectIds: selectMemberIds,
  selectAll: selectAllMember,
} = adapter.getSelectors();

export default adapter;
