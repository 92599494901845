/*
 *
 * EventVolunteerList constants
 *
 */
export const REDUCER_KEY = 'EventVolunteerList';
export const GET_EVENT_VOLUNTEER_LIST =
  'app/EventVolunteerList/GET_EVENT_VOLUNTEER_LIST';
export const GET_EVENT_VOLUNTEER_LIST_ERROR =
  'app/EventVolunteerList/GET_EVENT_VOLUNTEER_LIST_ERROR';
export const GET_EVENT_VOLUNTEER_LIST_SUCCESS =
  'app/EventVolunteerList/GET_EVENT_VOLUNTEER_LIST_SUCCESS';
export const RESET_GET_EVENT_VOLUNTEER_LIST =
  'app/EventVolunteerList/RESET_GET_EVENT_VOLUNTEER_LIST';

export const GET_VOLUNTEER_MISSIONS =
  'app/EventVolunteerList/GET_VOLUNTEER_MISSIONS';
export const GET_VOLUNTEER_MISSIONS_ERROR =
  'app/EventVolunteerList/GET_VOLUNTEER_MISSIONS_ERROR';
export const GET_VOLUNTEER_MISSIONS_SUCCESS =
  'app/EventVolunteerList/GET_VOLUNTEER_MISSIONS_SUCCESS';

export const GET_RESPONSIBLE_USERS =
  'app/EventVolunteerList/GET_RESPONSIBLE_USERS';
export const GET_RESPONSIBLE_USERS_ERROR =
  'app/EventVolunteerList/GET_RESPONSIBLE_USERS_ERROR';
export const GET_RESPONSIBLE_USERS_SUCCESS =
  'app/EventVolunteerList/GET_RESPONSIBLE_USERS_SUCCESS';

export const GET_VOLUNTEER_TAGS = 'app/EventVolunteerList/GET_VOLUNTEER_TAGS';
export const GET_VOLUNTEER_TAGS_ERROR =
  'app/EventVolunteerList/GET_VOLUNTEER_TAGS_ERROR';
export const GET_VOLUNTEER_TAGS_SUCCESS =
  'app/EventVolunteerList/GET_VOLUNTEER_TAGS_SUCCESS';

export const GET_UNIQUE_ZIPCODES = 'app/EventVolunteerList/GET_UNIQUE_ZIPCODES';
export const GET_UNIQUE_ZIPCODES_ERROR =
  'app/EventVolunteerList/GET_UNIQUE_ZIPCODES_ERROR';
export const GET_UNIQUE_ZIPCODES_SUCCESS =
  'app/EventVolunteerList/GET_UNIQUE_ZIPCODES_SUCCESS';
