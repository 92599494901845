/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/**
 *
 * ErrorBoundary
 *
 */

import React, { Component } from 'react';

import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  AlertTitle,
  Button,
  Paper,
  Typography,
} from '@mui/material';
import { injectIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { ExpandMore } from '@mui/icons-material';
import messages from './messages';

function withRouter(OriginalComponent) {
  function ComponentWithRouterProp(props) {
    const navigate = useNavigate();
    return <OriginalComponent {...props} router={{ navigate }} />;
  }

  return ComponentWithRouterProp;
}

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render shows the fallback UI.
    return { hasError: true, stackTrace: error.stack };
  }

  componentDidCatch() {
    // TODO: Integrate crashlytics
  }

  handleRefresh = () => {
    this.setState({ hasError: false, stackTrace: '' });
    this.props.navigate(0); // Refreshes the current route
  };

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Paper elevation={2}>
          <Alert severity="error">
            <AlertTitle>Error</AlertTitle>
            <Typography>
              {this.props.intl.formatMessage(messages.header)}
            </Typography>
            {process.env.NODE_ENV !== 'production' && (
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMore />}
                  aria-controls="error-stack"
                  id="error-stack"
                >
                  For developer
                </AccordionSummary>
                <AccordionDetails>
                  <pre>{this.state.stackTrace}</pre>
                </AccordionDetails>
              </Accordion>
            )}
            <Button onClick={this.handleRefresh}>refresh</Button>
          </Alert>
        </Paper>
      );
    }

    return this.props.children;
  }
}

export default withRouter(injectIntl(ErrorBoundary));
