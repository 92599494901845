import TrailyApiInstance from './base';
const BASE_URL = '/events';
export const getEventListApi = async params => {
  const response = await TrailyApiInstance.get(`${BASE_URL}`, {
    params,
  });
  return response;
};

export const getEventApi = async eventId => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/${eventId}`);
  return response;
};

export const getEventDetailApi = async ({ eventId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/detail`,
    {
      params,
    },
  );
  return response;
};

export const getEventTeamsApi = async (eventId, params) => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/${eventId}/teams`, {
    params,
  });
  return response;
};

export const getEventLinkFormsApi = async eventId => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/link-form`,
  );
  return response;
};

export const updateLinkFormApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/${eventId}/link-form`,
    data,
  );
  return response;
};

export const updateTeamSizeApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${eventId}/team-size`,
    data,
  );
  return response;
};

export const updateEventBasicSettingApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${eventId}/setting`,
    data,
  );
  return response;
};
export const updateSupporterRegLinkApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${eventId}/supporter-registration-link`,
    data,
  );
  return response;
};

export const getEventVolunteersApi = async (eventId, params) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/volunteers`,
    {
      params,
    },
  );
  return response;
};

export const getEventVolunteerTagsApi = async ({ eventId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/volunteer-tags`,
    {
      params,
    },
  );
  return response;
};

export const createVolunteerTagApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/${eventId}/volunteer-tags`,
    data,
  );
  return response;
};

export const updateVolunteerTagApi = async ({ eventId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${eventId}/volunteer-tags/${id}`,
    data,
  );
  return response;
};

export const deleteVolunteerTagApi = async ({ eventId, id }) => {
  const response = await TrailyApiInstance.delete(
    `${BASE_URL}/${eventId}/volunteer-tags/${id}`,
  );
  return response;
};

export const getEventTeamTagsApi = async ({ eventId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/team-tags`,
    {
      params,
    },
  );
  return response;
};

export const createTeamTagApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/${eventId}/team-tags`,
    data,
  );
  return response;
};

export const updateTeamTagApi = async ({ eventId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${eventId}/team-tags/${id}`,
    data,
  );
  return response;
};

export const deleteTeamTagApi = async ({ eventId, id }) => {
  const response = await TrailyApiInstance.delete(
    `${BASE_URL}/${eventId}/team-tags/${id}`,
  );
  return response;
};

export const getEventVolunteerGroupsApi = async ({ eventId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/volunteer-groups`,
    {
      params,
    },
  );
  return response;
};

export const createVolunteerGroupApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/${eventId}/volunteer-groups`,
    data,
  );
  return response;
};

export const updateVolunteerGroupApi = async ({ eventId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${eventId}/volunteer-groups/${id}`,
    data,
  );
  return response;
};

export const deleteVolunteerGroupApi = async ({ eventId, id }) => {
  const response = await TrailyApiInstance.delete(
    `${BASE_URL}/${eventId}/volunteer-groups/${id}`,
  );
  return response;
};

export const getEventMembersApi = async (eventId, params) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/members`,
    {
      params,
    },
  );
  return response;
};

export const getEventMemberTagsApi = async ({ eventId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/member-tags`,
    {
      params,
    },
  );
  return response;
};

export const createMemberTagApi = async ({ eventId, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/${eventId}/member-tags`,
    data,
  );
  return response;
};

export const updateMemberTagApi = async ({ eventId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${eventId}/member-tags/${id}`,
    data,
  );
  return response;
};

export const deleteMemberTagApi = async ({ eventId, id }) => {
  const response = await TrailyApiInstance.delete(
    `${BASE_URL}/${eventId}/member-tags/${id}`,
  );
  return response;
};

export const getMemberUniqueZipCodesApi = async eventId => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/member-zip-codes`,
  );
  return response;
};

export const getMemberUniqueSourcesApi = async eventId => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/member-sources`,
  );
  return response;
};

export const getMemberUniqueCountriesApi = async eventId => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/member-countries`,
  );
  return response;
};

export const getTeamUniqueCompaniesApi = async eventId => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/team-companies`,
  );
  return response;
};

export const getEventSupportersApi = async ({ eventId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/supporters`,
    {
      params,
    },
  );
  return response;
};

export const getSupporterUniqueZipCodesApi = async ({ eventId }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/supporters/zip-codes`,
  );
  return response;
};

export const getSupporterUniqueCountriesApi = async ({ eventId }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${eventId}/supporter/countries`,
  );
  return response;
};
