/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/display-name */
/**
 *
 * PrivateRoute
 *
 */

import React, { memo, useEffect } from 'react';
import { Navigate, Outlet, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Slide,
} from '@mui/material';
import { makeSelectLoggedInState } from '../../managers/AuthManager/selectors';
import SplashScreen from '../SplashScreen';
import {
  getCurrentUserAction,
  resetAuthStateAction,
} from '../../managers/AuthManager/actions';
import { Header } from '../Header';
import { resetLogin } from '../../managers/LoginManager/actions';

const Transition = React.forwardRef((props, ref) => (
  <Slide direction="up" ref={ref} {...props} />
));

export function PrivateRoute() {
  const isProduction = process.env.REACT_APP_IS_PROD_BUILD === 'true';

  // load current user
  const loggedInState = useSelector(makeSelectLoggedInState());
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getCurrentUserAction());
    return () => {
      dispatch(resetAuthStateAction());
      dispatch(resetLogin());
    };
  }, []);

  const isSessionDialogOpen = loggedInState === 'expired';

  const navigateOnSessionDialogClose = () => {
    dispatch(resetAuthStateAction());
    dispatch(resetLogin());
    navigate('login');
  };

  // if current user api call status is 2 and islogged in false, then show the session expired dialog
  if (loggedInState === 'failed' || loggedInState === 'logout') {
    return <Navigate to="/login"></Navigate>;
  }
  if (loggedInState === 'alive' || loggedInState === 'expired') {
    return (
      <>
        <Header />
        <Container
          maxWidth={false}
          sx={{
            mt: 2,
            backgroundColor: '#F4F4F4',
            maxWidth: '100vw',
            pb: isProduction ? 0 : 8,
          }}
        >
          {/* <StrictMode> */}
          <Outlet />
          {/* </StrictMode> */}
        </Container>
        <Dialog
          open={isSessionDialogOpen}
          TransitionComponent={Transition}
          keepMounted
          onClose={navigateOnSessionDialogClose}
          aria-describedby="session-expired-title"
        >
          <DialogTitle>Session expired</DialogTitle>
          <DialogContent>
            <DialogContentText id="session-expired-title">
              Your session has been expired. Please login again.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={navigateOnSessionDialogClose}>Login</Button>
          </DialogActions>
        </Dialog>
      </>
    );
  }

  return <SplashScreen />;
}

export default memo(PrivateRoute);
