/*
 *
 * AdminDashboard constants
 *
 */
export const REDUCER_KEY = 'AdminDashboard';

export const GET_USERS = 'app/AdminDashboard/GET_USERS';
export const GET_USERS_ERROR = 'app/AdminDashboard/GET_USERS_ERROR';
export const GET_USERS_SUCCESS = 'app/AdminDashboard/GET_USERS_SUCCESS';

export const CREATE_USER = 'app/AdminDashboard/CREATE_USER';
export const CREATE_USER_ERROR = 'app/AdminDashboard/CREATE_USER_ERROR';
export const CREATE_USER_SUCCESS = 'app/AdminDashboard/CREATE_USER_SUCCESS';

export const DEACTIVATE_USER = 'app/AdminDashboard/DEACTIVATE_USER';
export const DEACTIVATE_USER_ERROR = 'app/AdminDashboard/DEACTIVATE_USER_ERROR';
export const DEACTIVATE_USER_SUCCESS =
  'app/AdminDashboard/DEACTIVATE_USER_SUCCESS';

export const ACTIVATE_USER = 'app/AdminDashboard/ACTIVATE_USER';
export const ACTIVATE_USER_ERROR = 'app/AdminDashboard/ACTIVATE_USER_ERROR';
export const ACTIVATE_USER_SUCCESS = 'app/AdminDashboard/ACTIVATE_USER_SUCCESS';

export const TRANSFER_OWNER = 'app/AdminDashboard/TRANSFER_OWNER';
export const TRANSFER_OWNER_ERROR = 'app/AdminDashboard/TRANSFER_OWNER_ERROR';
export const TRANSFER_OWNER_SUCCESS =
  'app/AdminDashboard/TRANSFER_OWNER_SUCCESS';

export const RESET_ADMIN = 'app/AdminDashboard/RESET_ADMIN';
