/* eslint-disable react/prop-types */
/**
 *
 * LanguageProvider
 *
 */

import React, { memo, useEffect } from 'react';

import { useSelector } from 'react-redux';
import { IntlProvider } from 'react-intl';
import dayjs from 'dayjs';
import 'dayjs/locale/en';
import 'dayjs/locale/fr';
import { makeSelectLocale } from '../../managers/LanguageProviderManager/selectors';

export function LanguageProvider({ messages, children }) {
  const locale = useSelector(makeSelectLocale());
  useEffect(() => {
    switch (locale) {
      case 'en':
        dayjs.locale('en');
        break;
      default:
        dayjs.locale('fr');
        break;
    }
  }, [locale]);
  return (
    <IntlProvider locale={locale} key={locale} messages={messages[locale]}>
      {React.Children.only(children)}
    </IntlProvider>
  );
}

export default memo(LanguageProvider);
