/*
 *
 * LanguageProvider reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import { changeLocale } from './actions';

export const initialState = {
  locale: 'fr',
};

/* eslint-disable default-case, no-param-reassign */
const languageProviderSlice = createSlice({
  name: 'LanguageProvider',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(changeLocale, (state, { payload }) => {
      state.locale = payload;
    });
  },
});

export default languageProviderSlice.reducer;
