/*
 *
 * LanguageProvider actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import { CHANGE_LOCALE } from './constants';

export const changeLocale = createAction(CHANGE_LOCALE);
