/*
 *
 * SupporterDetail actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_SUPPORTER_DETAIL,
  GET_SUPPORTER_DETAIL_ERROR,
  GET_SUPPORTER_DETAIL_SUCCESS,
  UPDATE_SUPPORTER,
  UPDATE_SUPPORTER_ERROR,
  UPDATE_SUPPORTER_SUCCESS,
  RESET_SUPPORTER_DETAIL,
  DELETE_SUPPORTER,
  DELETE_SUPPORTER_ERROR,
  DELETE_SUPPORTER_SUCCESS,
  TRANSFER_MEAL,
  TRANSFER_MEAL_ERROR,
  TRANSFER_MEAL_SUCCESS,
  REMOVE_MEAL,
  REMOVE_MEAL_ERROR,
  REMOVE_MEAL_SUCCESS,
} from './constants';

export const getSupporterDetailAct = createAction(GET_SUPPORTER_DETAIL);
export const getSupporterDetailErrorAct = createAction(
  GET_SUPPORTER_DETAIL_ERROR,
);
export const getSupporterDetailSuccessAct = createAction(
  GET_SUPPORTER_DETAIL_SUCCESS,
);

export const updateSupporterAct = createAction(UPDATE_SUPPORTER);
export const updateSupporterErrorAct = createAction(UPDATE_SUPPORTER_ERROR);
export const updateSupporterSuccessAct = createAction(UPDATE_SUPPORTER_SUCCESS);

export const deleteSupporterAct = createAction(DELETE_SUPPORTER);
export const deleteSupporterErrorAct = createAction(DELETE_SUPPORTER_ERROR);
export const deleteSupporterSuccessAct = createAction(DELETE_SUPPORTER_SUCCESS);

export const transferMealAct = createAction(TRANSFER_MEAL);
export const transferMealErrorAct = createAction(TRANSFER_MEAL_ERROR);
export const transferMealSuccessAct = createAction(TRANSFER_MEAL_SUCCESS);

export const removeMealAct = createAction(REMOVE_MEAL);
export const removeMealErrorAct = createAction(REMOVE_MEAL_ERROR);
export const removeMealSuccessAct = createAction(REMOVE_MEAL_SUCCESS);

export const resetSupporterDetailAct = createAction(RESET_SUPPORTER_DETAIL);
