/*
 *
 * EventDetail constants
 *
 */
export const REDUCER_KEY = 'EventDetail';

export const GET_EVENT = 'app/EventDetail/GET_EVENT';
export const GET_EVENT_ERROR = 'app/EventDetail/GET_EVENT_ERROR';
export const GET_EVENT_SUCCESS = 'app/EventDetail/GET_EVENT_SUCCESS';

export const GET_EVENT_DETAIL = 'app/EventDetail/GET_EVENT_DETAIL';
export const GET_EVENT_DETAIL_ERROR = 'app/EventDetail/GET_EVENT_DETAIL_ERROR';
export const GET_EVENT_DETAIL_SUCCESS =
  'app/EventDetail/GET_EVENT_DETAIL_SUCCESS';

export const GET_LINK_FORMS = 'app/EventDetail/GET_LINK_FORMS';
export const GET_LINK_FORMS_ERROR = 'app/EventDetail/GET_LINK_FORMS_ERROR';
export const GET_LINK_FORMS_SUCCESS = 'app/EventDetail/GET_LINK_FORMS_SUCCESS';

export const LINK_FORM = 'app/EventDetail/LINK_FORM';
export const LINK_FORM_ERROR = 'app/EventDetail/LINK_FORM_ERROR';
export const LINK_FORM_SUCCESS = 'app/EventDetail/LINK_FORM_SUCCESS';

export const UPDATE_EVENT_BASIC_SETTING =
  'app/EventDetail/UPDATE_EVENT_BASIC_SETTING';
export const UPDATE_EVENT_BASIC_SETTING_ERROR =
  'app/EventDetail/UPDATE_EVENT_BASIC_SETTING_ERROR';
export const UPDATE_EVENT_BASIC_SETTING_SUCCESS =
  'app/EventDetail/UPDATE_EVENT_BASIC_SETTING_SUCCESS';

export const RESET_GET_EVENT_STATS = 'app/EventDetail/RESET_GET_EVENT_STATS';
export const RESET_GET_EVENT_DETAIL = 'app/EventDetail/RESET_GET_EVENT_DETAIL';
export const RESET_LINK_FORM_STATE = 'app/EventDetail/RESET_LINK_FORM_STATE';
