import TrailyApiInstance from './base';
const BASE_URL = '/teams';

export const getTeamDetail = async teamId => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/${teamId}`);
  return response;
};

export const getNotes = async ({ teamId, params }) => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/${teamId}/notes`, {
    params,
  });
  return response;
};

export const createNote = async ({ teamId, data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/${teamId}/notes`,
    data,
  );
  return response;
};

export const updateNoteApi = async ({ teamId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${teamId}/notes/${id}`,
    data,
  );
  return response;
};

export const updatePinStatus = async ({ teamId, id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${teamId}/notes/${id}/update-pin`,
    data,
  );
  return response;
};

export const deleteNote = async ({ teamId, id }) => {
  const response = await TrailyApiInstance.delete(
    `${BASE_URL}/${teamId}/notes/${id}`,
  );
  return response;
};

export const setBibNumber = async ({ teamId, bibNumber }) => {
  const response = await TrailyApiInstance.post(`${BASE_URL}/${teamId}/bib`, {
    bibNumber,
  });
  return response;
};
export const getDonationStats = async ({ teamId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${teamId}/donation-stat`,
    { params },
  );
  return response;
};

export const getDonationGoal = async teamId => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/${teamId}/goal`);
  return response;
};

export const updateTagApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/update-tag`,
    data,
  );
  return response;
};

export const updateStartTimeApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/start-time`,
    data,
  );
  return response;
};

export const updateStatusApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/status`,
    data,
  );
  return response;
};

export const assignUserApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/assign-user`,
    data,
  );
  return response;
};

export const getUniqueCompanyNamesApi = async eventId => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/company-names/${eventId}`,
  );
  return response;
};

export const updateWelcomeStatusApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/welcome-status`,
    data,
  );
  return response;
};

export const updateBoardingStatusApi = async ({ id, data }) => {
  const response = await TrailyApiInstance.put(
    `${BASE_URL}/${id}/boarding-status`,
    data,
  );
  return response;
};

export const getTeamSupportersApi = async ({ teamId, params }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${teamId}/supporters`,
    {
      params,
    },
  );
  return response;
};

export const getSupporterUniqueZipCodesApi = async ({ teamId }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${teamId}/supporters/zip-codes`,
  );
  return response;
};

export const getSupporterUniqueCountriesApi = async ({ teamId }) => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/${teamId}/supporters/countries`,
  );
  return response;
};
