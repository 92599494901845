/*
 *
 * EventVolunteerList actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_EVENT_VOLUNTEER_LIST,
  GET_EVENT_VOLUNTEER_LIST_ERROR,
  GET_EVENT_VOLUNTEER_LIST_SUCCESS,
  GET_RESPONSIBLE_USERS,
  GET_RESPONSIBLE_USERS_ERROR,
  GET_RESPONSIBLE_USERS_SUCCESS,
  GET_UNIQUE_ZIPCODES,
  GET_UNIQUE_ZIPCODES_ERROR,
  GET_UNIQUE_ZIPCODES_SUCCESS,
  GET_VOLUNTEER_MISSIONS,
  GET_VOLUNTEER_MISSIONS_ERROR,
  GET_VOLUNTEER_MISSIONS_SUCCESS,
  GET_VOLUNTEER_TAGS,
  GET_VOLUNTEER_TAGS_ERROR,
  GET_VOLUNTEER_TAGS_SUCCESS,
  RESET_GET_EVENT_VOLUNTEER_LIST,
} from './constants';

export const getEventVolunteerList = createAction(GET_EVENT_VOLUNTEER_LIST);
export const getEventVolunteerListError = createAction(
  GET_EVENT_VOLUNTEER_LIST_ERROR,
);
export const getEventVolunteerListSuccess = createAction(
  GET_EVENT_VOLUNTEER_LIST_SUCCESS,
);
export const getVolunteerMissions = createAction(GET_VOLUNTEER_MISSIONS);
export const getVolunteerMissionsError = createAction(
  GET_VOLUNTEER_MISSIONS_ERROR,
);
export const getVolunteerMissionsSuccess = createAction(
  GET_VOLUNTEER_MISSIONS_SUCCESS,
);

export const getResponsibleUsers = createAction(GET_RESPONSIBLE_USERS);
export const getResponsibleUsersError = createAction(
  GET_RESPONSIBLE_USERS_ERROR,
);
export const getResponsibleUsersSuccess = createAction(
  GET_RESPONSIBLE_USERS_SUCCESS,
);

export const getVolunteerTags = createAction(GET_VOLUNTEER_TAGS);
export const getVolunteerTagsError = createAction(GET_VOLUNTEER_TAGS_ERROR);
export const getVolunteerTagsSuccess = createAction(GET_VOLUNTEER_TAGS_SUCCESS);

export const getUniqueZipCodesAct = createAction(GET_UNIQUE_ZIPCODES);
export const getUniqueZipCodesActError = createAction(
  GET_UNIQUE_ZIPCODES_ERROR,
);
export const getUniqueZipCodesActSuccess = createAction(
  GET_UNIQUE_ZIPCODES_SUCCESS,
);

export const resetGetEventVolunteerList = createAction(
  RESET_GET_EVENT_VOLUNTEER_LIST,
);
