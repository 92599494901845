import { takeLatest, call, put } from 'redux-saga/effects';
import {
  activateUserApi,
  createUserApi,
  deactivateUserApi,
  getUsersApi,
  transferOwnerApi,
  updateUserApi,
} from '../../api/admin.api';
import {
  activateUserErrorAct,
  activateUserSuccessAct,
  createUserErrorAct,
  createUserSuccessAct,
  deactivateUserErrorAct,
  deactivateUserSuccessAct,
  getUsersErrorAct,
  getUsersSuccessAct,
  transferOwnerErrorAct,
  transferOwnerSuccessAct,
} from './actions';
import {
  ACTIVATE_USER,
  CREATE_USER,
  DEACTIVATE_USER,
  GET_USERS,
  TRANSFER_OWNER,
} from './constants';

function* getUsers({ payload }) {
  try {
    const { data } = yield call(getUsersApi, payload);
    if (data) {
      yield put(getUsersSuccessAct(data));
    } else {
      yield put(getUsersErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getUsersErrorAct(errMessage));
  }
}

function* createUser({ payload }) {
  try {
    let data;
    if (!payload.id) {
      const { data: createdUser } = yield call(createUserApi, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        password: payload.password,
        confirmPassword: payload.confirmPassword,
        roleId: payload.roleId,
      });
      data = createdUser;
    } else {
      const { data: updatedUser } = yield call(updateUserApi, payload.id, {
        firstName: payload.firstName,
        lastName: payload.lastName,
        email: payload.email,
        roleId: payload.roleId,
      });
      data = updatedUser;
    }
    if (data) {
      yield put(createUserSuccessAct(data));
    } else {
      yield put(createUserErrorAct({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    yield put(createUserErrorAct(response.data));
  }
}

function* transferOwner({ payload }) {
  try {
    const { data } = yield call(transferOwnerApi, payload);
    if (data) {
      yield put(transferOwnerSuccessAct(data));
    } else {
      yield put(transferOwnerErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(transferOwnerErrorAct(errMessage));
  }
}

function* deactivateUser({ payload }) {
  try {
    const { data } = yield call(deactivateUserApi, payload);
    if (data) {
      yield put(deactivateUserSuccessAct(data));
    } else {
      yield put(deactivateUserErrorAct({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    yield put(deactivateUserErrorAct(response.data));
  }
}

function* activateUser({ payload }) {
  try {
    const { data } = yield call(activateUserApi, payload);
    if (data) {
      yield put(activateUserSuccessAct(data));
    } else {
      yield put(activateUserErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(activateUserErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* adminDashboardSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_USERS, getUsers);
  yield takeLatest(CREATE_USER, createUser);
  yield takeLatest(DEACTIVATE_USER, deactivateUser);
  yield takeLatest(ACTIVATE_USER, activateUser);
  yield takeLatest(TRANSFER_OWNER, transferOwner);
}
