import { createManager } from 'redux-injectors';

import reducer from './reducer';

import { REDUCER_KEY } from './constants';

const LanguageProviderManager = createManager({
  name: 'LanguageProviderManager',
  key: REDUCER_KEY,
  reducer,
});

export default LanguageProviderManager;
