import { getContrastRatio } from '@mui/material/styles';

export const stringToColor = string => {
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = '#';

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
};

export const stringAvatar = name => {
  const words = name.split(' ').filter(word => word !== '');
  let children = '';
  // eslint-disable-next-line prefer-destructuring
  children = words[0][0];
  if (words.length > 1) {
    children += words[1][0];
  }
  const bgColor = stringToColor(name);
  const color = getContrastRatio(bgColor, '#fff') > 4.5 ? '#fff' : '#111';
  return {
    sx: {
      bgcolor: bgColor,
      color,
    },
    children,
  };
};

export const formDisplayName = (firstName, midlleName, lastName) => {
  let fullName = `${firstName}`;
  fullName += midlleName ? ` ${midlleName}` : '';
  fullName += lastName ? ` ${lastName}` : '';
  return fullName || '';
};

export const getColorBasedOnStatus = status => {
  switch (status) {
    case 'Pending':
    case 'Not verified':
    case 'Postponed':
    case 'WC to do':
    case 'WE to do':
      return 'warning';
    case 'In progress':
    case 'in_progress':
      return 'info';
    case 'Canceled':
    case 'Dropped':
    case 'Unknown':
    case 'Refused':
      return 'error';
    default:
      return 'success';
  }
};

export const addApostropheForZeroLeading = value => {
  // number to string
  if (value) {
    const valueStr = value.toString();
    if (valueStr.indexOf(0) === 0) {
      return `'${valueStr}`;
    }
  }
  return value;
};
