import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  CREATE_MEMBER_TAGS,
  DELETE_MEMBER_TAGS,
  GET_MEMBER_TAGS_LIST,
  UPDATE_MEMBER_TAGS,
} from './constants';

import {
  createMemberTagApi,
  deleteMemberTagApi,
  getEventMemberTagsApi,
  updateMemberTagApi,
} from '../../api/event.api';

import {
  getMemberTagsListErrorAct,
  getMemberTagsListSuccessAct,
  deleteMemberTagsErrorAct,
  deleteMemberTagsSuccessAct,
  updateMemberTagsSuccessAct,
  updateMemberTagsErrorAct,
  createMemberTagsErrorAct,
  createMemberTagsSuccessAct,
} from './actions';

function* fetchMemberTags({ payload }) {
  try {
    const { data } = yield call(getEventMemberTagsApi, payload);
    if (data) {
      yield put(getMemberTagsListSuccessAct(data));
    } else {
      yield put(getMemberTagsListErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getMemberTagsListErrorAct(errMessage));
  }
}

function* deleteMemberTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteMemberTagApi, payload);
    if (data) {
      yield put(deleteMemberTagsSuccessAct({ id }));
    } else {
      yield put(deleteMemberTagsErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(deleteMemberTagsErrorAct(errMessage));
  }
}

function* updateMemberTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateMemberTagApi, payload);
    if (data) {
      yield put(
        updateMemberTagsSuccessAct({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
    } else {
      yield put(updateMemberTagsErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateMemberTagsErrorAct(errMessage));
  }
}

function* createMemberTags({ payload }) {
  try {
    const { data } = yield call(createMemberTagApi, payload);
    if (data) {
      yield put(createMemberTagsSuccessAct(data));
    } else {
      yield put(createMemberTagsErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(createMemberTagsErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* memberTagsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_MEMBER_TAGS_LIST, fetchMemberTags);
  yield takeEvery(DELETE_MEMBER_TAGS, deleteMemberTags);
  yield takeEvery(UPDATE_MEMBER_TAGS, updateMemberTags);
  yield takeEvery(CREATE_MEMBER_TAGS, createMemberTags);
}
