/*
 *
 * Login constants
 *
 */
export const REDUCER_KEY = 'Login';
export const PERFORM_LOGIN = 'app/Login/perfom-login';
export const PERFORM_LOGIN_SUCCESS = 'app/Login/login-success';
export const PERFORM_LOGIN_ERROR = 'app/Login/login-error';
export const PERFORM_LOGIN_RESET = 'app/Login/login-reset';
