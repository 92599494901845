import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the languageProvider state domain
 */

const selectLanguageProviderDomain = state =>
  state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by LanguageProvider
 */

const makeSelectLanguageProvider = () =>
  createSelector(selectLanguageProviderDomain, substate => substate);

const makeSelectLocale = () =>
  createSelector(selectLanguageProviderDomain, substate => substate.locale);

export default makeSelectLanguageProvider;
export { selectLanguageProviderDomain, makeSelectLocale };
