import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter({});

export const {
  selectById: selectSupporterById,
  selectIds: selectSupporterIds,
  selectAll: selectSupporters,
} = adapter.getSelectors();

export default adapter;
