/*
 *
 * Login actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  PERFORM_LOGIN,
  PERFORM_LOGIN_ERROR,
  PERFORM_LOGIN_SUCCESS,
  PERFORM_LOGIN_RESET,
} from './constants';

export const loginAction = createAction(PERFORM_LOGIN);
export const loginErrorAction = createAction(PERFORM_LOGIN_ERROR);
export const loginSuccessAction = createAction(PERFORM_LOGIN_SUCCESS);
export const resetLogin = createAction(PERFORM_LOGIN_RESET);
