/*
 *
 * EventDetail actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_EVENT,
  GET_EVENT_DETAIL,
  GET_EVENT_DETAIL_ERROR,
  GET_EVENT_DETAIL_SUCCESS,
  GET_EVENT_ERROR,
  GET_EVENT_SUCCESS,
  GET_LINK_FORMS,
  GET_LINK_FORMS_ERROR,
  GET_LINK_FORMS_SUCCESS,
  LINK_FORM,
  LINK_FORM_ERROR,
  LINK_FORM_SUCCESS,
  RESET_GET_EVENT_DETAIL,
  RESET_GET_EVENT_STATS,
  RESET_LINK_FORM_STATE,
  UPDATE_EVENT_BASIC_SETTING,
  UPDATE_EVENT_BASIC_SETTING_ERROR,
  UPDATE_EVENT_BASIC_SETTING_SUCCESS,
} from './constants';
export const getEventAct = createAction(GET_EVENT);
export const getEventErrorAct = createAction(GET_EVENT_ERROR);
export const getEventSuccessAct = createAction(GET_EVENT_SUCCESS);

export const getEventDetailAct = createAction(GET_EVENT_DETAIL);
export const getEventDetailErrorAct = createAction(GET_EVENT_DETAIL_ERROR);
export const getEventDetailSuccessAct = createAction(GET_EVENT_DETAIL_SUCCESS);

export const getLinkFormsAct = createAction(GET_LINK_FORMS);
export const getLinkFormsErrorAct = createAction(GET_LINK_FORMS_ERROR);
export const getLinkFormsSuccessAct = createAction(GET_LINK_FORMS_SUCCESS);

export const linkForm = createAction(LINK_FORM);
export const linkFormError = createAction(LINK_FORM_ERROR);
export const linkFormSuccess = createAction(LINK_FORM_SUCCESS);

export const updateEventBasicSettingAct = createAction(
  UPDATE_EVENT_BASIC_SETTING,
);
export const updateEventBasicSettingErrorAct = createAction(
  UPDATE_EVENT_BASIC_SETTING_ERROR,
);
export const updateEventBasicSettingSuccessAct = createAction(
  UPDATE_EVENT_BASIC_SETTING_SUCCESS,
);

export const resetGetEventDetail = createAction(RESET_GET_EVENT_DETAIL);
export const resetFormLinkStateAct = createAction(RESET_LINK_FORM_STATE);
export const resetGetEventStatsAct = createAction(RESET_GET_EVENT_STATS);
