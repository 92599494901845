/**
 *
 * Header
 *
 */

import React, { memo } from 'react';
// import { useIntl } from 'react-intl';

// import messages from './messages';
import { Appbar } from './Appbar';

export function Header() {
  // const intl = useIntl();
  // const header = intl.formatMessage(messages.header);

  return <Appbar></Appbar>;
}

export default memo(Header);
