/*
 *
 * InternalUser reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';
import usersEntityAdapter from './entity-adapter';

import {
  getInternalUser,
  getInternalUserError,
  getInternalUserSuccess,
  resetGetInternalUser,
  getInternalUsers,
  getInternalUsersError,
  getInternalUsersSuccess,
  changePasswordAct,
  changePasswordErrorAct,
  changePasswordSuccessAct,
} from './actions';

export const initialState = usersEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  changePasswordState: 0,
});

/* eslint-disable default-case, no-param-reassign */
const internalUserSlice = createSlice({
  name: 'InternalUser',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder.addCase(getInternalUser, state => {
      state.loadingState = 1;
      state.error = '';
    });
    builder.addCase(getInternalUserError, (state, { payload }) => {
      state.loadingState = 3;
      state.error = payload;
    });
    builder.addCase(getInternalUserSuccess, (state, { payload }) => {
      state.loadingState = 2;
      state.error = '';
      usersEntityAdapter.upsertOne(state, payload);
    });
    builder.addCase(changePasswordAct, state => {
      state.changePasswordState = 1;
      state.error = '';
    });
    builder.addCase(changePasswordErrorAct, (state, { payload }) => {
      state.changePasswordState = 3;
      state.error = payload;
    });
    builder.addCase(changePasswordSuccessAct, state => {
      state.changePasswordState = 2;
      state.error = '';
    });
    builder.addCase(getInternalUsers, state => {
      state.loadingState = 1;
      state.error = '';
    });
    builder.addCase(getInternalUsersError, (state, { payload }) => {
      state.loadingState = 3;
      state.error = payload;
    });
    builder.addCase(getInternalUsersSuccess, (state, { payload }) => {
      state.loadingState = 2;
      state.error = '';
      usersEntityAdapter.upsertMany(state, payload.rows);
      state.totalPages = payload.totalPages;
    });
    builder.addCase(resetGetInternalUser, state => {
      state.loadingState = 0;
      state.error = '';
      state.totalPages = 0;
      state.changePasswordState = 0;
      usersEntityAdapter.removeAll(state);
    });
  },
});

export default internalUserSlice.reducer;
