/*
 *
 * EventDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  resetGetEventDetail,
  linkForm,
  linkFormError,
  linkFormSuccess,
  resetFormLinkStateAct,
  getEventDetailAct,
  getEventDetailErrorAct,
  getEventDetailSuccessAct,
  getEventAct,
  getEventErrorAct,
  getEventSuccessAct,
  getLinkFormsAct,
  getLinkFormsErrorAct,
  getLinkFormsSuccessAct,
  resetGetEventStatsAct,
  updateEventBasicSettingAct,
  updateEventBasicSettingErrorAct,
  updateEventBasicSettingSuccessAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  event: null,
  eventDetail: null,
  eventDetailLoadingState: 0,
  forms: null,
  formsLoadingState: 1,
  linkFormState: 0,
  basicSettingState: 0,
  supporterRegLinkState: 0,
  confirmedTeamCount: 0,
  confirmedMemberCount: 0,
  confirmedVolunteerCount: 0,
  confirmedSupporterCount: 0,
  confirmedCompanyTeam: 0,
};

/* eslint-disable default-case, no-param-reassign */
const eventDetailSlice = createSlice({
  name: 'EventDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventAct, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getEventErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getEventSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.event = payload;
      })
      .addCase(getEventDetailAct, state => {
        state.eventDetailLoadingState = 1;
        state.error = '';
      })
      .addCase(getEventDetailErrorAct, (state, { payload }) => {
        state.eventDetailLoadingState = 3;
        state.error = payload;
      })
      .addCase(getEventDetailSuccessAct, (state, { payload }) => {
        state.eventDetailLoadingState = 2;
        state.error = '';
        state.eventDetail = payload;
        if (payload.ob !== true) {
          const {
            teamCount,
            volunteerCount,
            memberCount,
            supporterCount,
            teamStats,
          } = payload;
          state.confirmedTeamCount = teamCount;
          state.confirmedMemberCount = memberCount;
          state.confirmedVolunteerCount = volunteerCount;
          state.confirmedSupporterCount = supporterCount;
          state.confirmedCompanyTeam = teamStats?.teamFromCompanyCount || 0;
        }
      })
      .addCase(getLinkFormsAct, state => {
        state.formsLoadingState = 1;
        state.error = '';
      })
      .addCase(getLinkFormsErrorAct, (state, { payload }) => {
        state.formsLoadingState = 3;
        state.error = payload;
      })
      .addCase(getLinkFormsSuccessAct, (state, { payload }) => {
        state.formsLoadingState = 2;
        state.error = '';
        state.forms = payload;
      })
      .addCase(updateEventBasicSettingAct, state => {
        state.basicSettingState = 1;
        state.error = '';
      })
      .addCase(updateEventBasicSettingErrorAct, (state, { payload }) => {
        state.basicSettingState = 3;
        state.error = payload;
      })
      .addCase(updateEventBasicSettingSuccessAct, (state, { payload }) => {
        state.basicSettingState = 2;
        state.error = '';
        state.event = { ...state.event, ...payload };
      })
      .addCase(linkForm, (state, { payload }) => {
        const { data } = payload;
        switch (data.type) {
          case 'volunteer':
            if (state.forms) state.forms.volunteerForm = data;
            break;
          case 'supporter':
            if (state.forms) state.forms.supporterForm = data;
            break;
          case 'supportermeal':
            if (state.forms) state.forms.supporterMealForm = data;
            break;
          case 'teammeal':
            if (state.forms) state.forms.teamMealForm = data;
            break;
          case 'transport':
            if (state.forms) state.forms.transportForm = data;
            break;
          case 'thf':
            if (state.forms) state.forms.teamHostingForm = data;
            break;
          case 'shf':
            if (state.forms) state.forms.supporterHostingForm = data;
            break;
        }
        state.linkFormState = 1;
        state.error = '';
      })
      .addCase(linkFormError, (state, { payload }) => {
        const { error, oldValue, type } = payload;
        switch (type) {
          case 'volunteer':
            if (state.forms) state.forms.volunteerForm = oldValue;
            break;
          case 'supporter':
            if (state.forms) state.forms.supporterForm = oldValue;
            break;
          case 'supportermeal':
            if (state.forms) state.forms.supporterMealForm = oldValue;
            break;
          case 'teammeal':
            if (state.forms) state.forms.teamMealForm = oldValue;
            break;
          case 'transport':
            if (state.forms) state.forms.transportForm = oldValue;
            break;
          case 'thf':
            if (state.forms) state.forms.teamHostingForm = oldValue;
            break;
          case 'shf':
            if (state.forms) state.forms.supporterHostingForm = oldValue;
            break;
        }
        state.linkFormState = 3;
        state.error = error;
      })
      .addCase(linkFormSuccess, (state, { payload }) => {
        const { type, id, externalId, title } = payload;
        switch (type) {
          case 'volunteer':
            if (state.forms)
              state.forms.volunteerForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'supporter':
            if (state.forms)
              state.forms.supporterForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'supportermeal':
            if (state.forms)
              state.forms.supporterMealForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'teammeal':
            if (state.forms)
              state.forms.teamMealForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'transport':
            if (state.forms)
              state.forms.transportForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'thf':
            if (state.forms)
              state.forms.teamHostingForm = {
                id,
                externalId,
                title,
              };
            break;
          case 'shf':
            if (state.forms)
              state.forms.supporterHostingForm = {
                id,
                externalId,
                title,
              };
            break;
        }
        state.linkFormState = 2;
        state.error = '';
      })
      .addCase(resetFormLinkStateAct, state => {
        state.linkFormState = 0;
        state.error = '';
        state.forms = null;
        state.basicSettingState = 0;
      })
      .addCase(resetGetEventStatsAct, state => {
        state.eventDetailLoadingState = 0;
        state.error = '';
        state.eventDetail = null;
      })
      .addCase(resetGetEventDetail, () => initialState);
  },
});

export default eventDetailSlice.reducer;
