/*
 *
 * TeamTags reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createTeamTags,
  createTeamTagsError,
  createTeamTagsSuccess,
  deleteTeamTags,
  deleteTeamTagsError,
  deleteTeamTagsSuccess,
  getTeamTagsList,
  getTeamTagsListError,
  getTeamTagsListSuccess,
  resetGetTeamTagsList,
  setActiveNoteItem,
  updateTeamTags,
  updateTeamTagsError,
  updateTeamTagsSuccess,
} from './actions';

import teamTagsEntityAdapter from './entity-adapter';

export const initialState = teamTagsEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  activeId: '',
  activeState: '',
  activeAction: 0,
  activeError: '',
  createState: 0,
  createError: '',
});

/* eslint-disable default-case, no-param-reassign */
const TeamTagsSlice = createSlice({
  name: 'TeamTags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getTeamTagsList, (state, { payload }) => {
        state.loadingState = 1;
        state.error = '';
        if (payload.params && payload.params.page === 1) {
          teamTagsEntityAdapter.removeAll(state);
        }
      })
      .addCase(getTeamTagsListError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getTeamTagsListSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        teamTagsEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(setActiveNoteItem, (state, { payload }) => {
        const { id, actionName, actionState, actionError } = payload;
        state.activeAction = actionName;
        state.activeState = actionState;
        state.activeError = actionError;
        state.activeId = id;
      })
      .addCase(deleteTeamTags, state => {
        state.activeState = 1;
        state.activeAction = 'Delete';
        state.activeError = '';
      })
      .addCase(deleteTeamTagsError, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(deleteTeamTagsSuccess, (state, { payload }) => {
        const { id } = payload;
        state.activeState = 2;
        teamTagsEntityAdapter.removeOne(state, id);
      })
      .addCase(updateTeamTags, state => {
        state.activeState = 1;
        state.activeAction = 'Update';
        state.activeError = '';
      })
      .addCase(updateTeamTagsError, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(updateTeamTagsSuccess, (state, { payload }) => {
        const { id, name, updatedAt } = payload;
        state.activeState = 2;
        state.error = '';
        if (id && state.entities[id]) {
          teamTagsEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              updatedAt,
            },
          });
        }
      })
      .addCase(createTeamTags, state => {
        state.createState = 1;
        state.createError = '';
      })
      .addCase(createTeamTagsError, (state, { payload }) => {
        state.createState = 3;
        state.createError = payload;
      })
      .addCase(createTeamTagsSuccess, (state, { payload }) => {
        state.createState = 2;
        teamTagsEntityAdapter.addOne(state, payload);
      })
      .addCase(resetGetTeamTagsList, state => {
        state.loadingState = 0;
        state.error = '';
        teamTagsEntityAdapter.removeAll(state);
        state.totalPages = 0;
        state.count = 0;
        state.activeId = '';
        state.activeState = '';
        state.activeAction = 0;
        state.activeError = '';
        state.createState = 0;
        state.createError = '';
      });
  },
});

export default TeamTagsSlice.reducer;
