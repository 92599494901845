import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  CREATE_VOLUNTEER_TAGS,
  DELETE_VOLUNTEER_TAGS,
  GET_VOLUNTEER_TAGS_LIST,
  UPDATE_VOLUNTEER_TAGS,
} from './constants';

import {
  createVolunteerTagApi,
  deleteVolunteerTagApi,
  getEventVolunteerTagsApi,
  updateVolunteerTagApi,
} from '../../api/event.api';

import {
  getVolunteerTagsListError,
  getVolunteerTagsListSuccess,
  deleteVolunteerTagsError,
  deleteVolunteerTagsSuccess,
  updateVolunteerTagsSuccess,
  updateVolunteerTagsError,
  createVolunteerTagsError,
  createVolunteerTagsSuccess,
} from './actions';
function* fetchVolunteerTags({ payload }) {
  try {
    const { data } = yield call(getEventVolunteerTagsApi, payload);
    if (data) {
      yield put(getVolunteerTagsListSuccess(data));
    } else {
      yield put(getVolunteerTagsListError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getVolunteerTagsListError(errMessage));
  }
}

function* deleteVolunteerTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(deleteVolunteerTagApi, payload);
    if (data) {
      yield put(deleteVolunteerTagsSuccess({ id }));
    } else {
      yield put(deleteVolunteerTagsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(deleteVolunteerTagsError(errMessage));
  }
}

function* updateVolunteerTags({ payload }) {
  const { id } = payload;

  try {
    const { data } = yield call(updateVolunteerTagApi, payload);
    if (data) {
      yield put(
        updateVolunteerTagsSuccess({
          id,
          name: data.name,
          updatedAt: data.updatedAt,
        }),
      );
    } else {
      yield put(updateVolunteerTagsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateVolunteerTagsError(errMessage));
  }
}

function* createVolunteerTags({ payload }) {
  try {
    const { data } = yield call(createVolunteerTagApi, payload);
    if (data) {
      yield put(createVolunteerTagsSuccess(data));
    } else {
      yield put(createVolunteerTagsError('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(createVolunteerTagsError(errMessage));
  }
}

// Individual exports for testing
export default function* volunteerTagsSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_VOLUNTEER_TAGS_LIST, fetchVolunteerTags);
  yield takeEvery(DELETE_VOLUNTEER_TAGS, deleteVolunteerTags);
  yield takeEvery(UPDATE_VOLUNTEER_TAGS, updateVolunteerTags);
  yield takeEvery(CREATE_VOLUNTEER_TAGS, createVolunteerTags);
}
