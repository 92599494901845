/*
 *
 * MemberTags actions
 *
 */

import { createAction } from '@reduxjs/toolkit';

import {
  CREATE_MEMBER_TAGS,
  CREATE_MEMBER_TAGS_ERROR,
  CREATE_MEMBER_TAGS_SUCCESS,
  DELETE_MEMBER_TAGS,
  DELETE_MEMBER_TAGS_ERROR,
  DELETE_MEMBER_TAGS_SUCCESS,
  GET_MEMBER_TAGS_LIST,
  GET_MEMBER_TAGS_LIST_ERROR,
  GET_MEMBER_TAGS_LIST_SUCCESS,
  RESET_GET_MEMBER_TAGS_LIST,
  SET_ACTIVE_TAGS_ITEM,
  UPDATE_MEMBER_TAGS,
  UPDATE_MEMBER_TAGS_ERROR,
  UPDATE_MEMBER_TAGS_SUCCESS,
} from './constants';

export const getMemberTagsListAct = createAction(GET_MEMBER_TAGS_LIST);
export const getMemberTagsListErrorAct = createAction(
  GET_MEMBER_TAGS_LIST_ERROR,
);
export const getMemberTagsListSuccessAct = createAction(
  GET_MEMBER_TAGS_LIST_SUCCESS,
);
export const resetGetMemberTagsListAct = createAction(
  RESET_GET_MEMBER_TAGS_LIST,
);
export const deleteMemberTagsAct = createAction(DELETE_MEMBER_TAGS);
export const deleteMemberTagsErrorAct = createAction(DELETE_MEMBER_TAGS_ERROR);
export const deleteMemberTagsSuccessAct = createAction(
  DELETE_MEMBER_TAGS_SUCCESS,
);
export const updateMemberTagsAct = createAction(UPDATE_MEMBER_TAGS);
export const updateMemberTagsErrorAct = createAction(UPDATE_MEMBER_TAGS_ERROR);
export const updateMemberTagsSuccessAct = createAction(
  UPDATE_MEMBER_TAGS_SUCCESS,
);
export const setActiveTagItemAct = createAction(SET_ACTIVE_TAGS_ITEM);
export const createMemberTagsAct = createAction(CREATE_MEMBER_TAGS);
export const createMemberTagsErrorAct = createAction(CREATE_MEMBER_TAGS_ERROR);
export const createMemberTagsSuccessAct = createAction(
  CREATE_MEMBER_TAGS_SUCCESS,
);
