/* eslint-disable import/no-unresolved  */
import { createTheme, responsiveFontSizes } from '@mui/material/styles';

import { ThemeData } from '@theme';

const theme = createTheme(ThemeData);

const MuiTheme = responsiveFontSizes(theme);

export default MuiTheme;
