import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter({});

export const {
  selectById: selectMemberById,
  selectIds: selectMemberIds,
  selectAll: selectMembers,
} = adapter.getSelectors();

export default adapter;
