import React, { useMemo, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

import {
  AppBar,
  Avatar,
  Box,
  Button,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  Hidden,
} from '@mui/material';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';

import { useDispatch, useSelector } from 'react-redux';
import { GTranslate, KeyboardArrowUp } from '@mui/icons-material';
import { useIntl } from 'react-intl';
import Logo from '@images/logo-with-text.png';
import {
  makeSelectUser,
  makeSelectViewRole,
} from '../../managers/AuthManager/selectors';
import {
  performLogoutAction,
  updateViewRoleAct,
} from '../../managers/AuthManager/actions';
import { resetLogin } from '../../managers/LoginManager/actions';
import { DynamicHeaderTitle } from './DynamicHeaderTitle';
import { stringAvatar } from '../../utils/common.util';

import { appLocales } from '../../i18n';

import messages from './messages';
import commonMessages from '../common/messages';
import { makeSelectLocale } from '../../managers/LanguageProviderManager/selectors';
import { changeLocale } from '../../managers/LanguageProviderManager/actions';

export function Appbar() {
  const intl = useIntl();

  // translation text
  const {
    profileText,
    logoutText,
    manageUserTxt,
    confirmationTxt,
    logoutWarnTxt,
    cancelTxt,
    logoutTxt,
    switchRoleToAdminTxt,
    switchRoleToOnboarderTxt,
  } = useMemo(
    () => ({
      profileText: intl.formatMessage(messages.profile),
      logoutText: intl.formatMessage(messages.logout),
      manageUserTxt: intl.formatMessage(messages.manageUser),
      confirmationTxt: intl.formatMessage(commonMessages.confirmation),
      logoutWarnTxt: intl.formatMessage(messages.logoutWarn),
      cancelTxt: intl.formatMessage(commonMessages.cancel),
      logoutTxt: intl.formatMessage(messages.logout),
      switchRoleToAdminTxt: intl.formatMessage(messages.switchRoleToAdmin),
      switchRoleToOnboarderTxt: intl.formatMessage(
        messages.switchRoleToOnboarder,
      ),
    }),
    [intl],
  );

  const currentUser = useSelector(makeSelectUser());
  const currentViewRole = useSelector(makeSelectViewRole());
  const currentLocale = useSelector(makeSelectLocale());
  const dispatch = useDispatch();
  const [anchorElUser, setAnchorElUser] = useState(null);
  const [anchorElLanguage, setAnchorElLanguage] = useState(null);
  const [isLogoutConfirmOpen, setIsLogoutConfirmOpen] = useState(false);

  const avatarProp = stringAvatar(currentUser.displayName);

  let isAdmin = false;
  if (currentUser && currentUser.role) {
    isAdmin = currentUser.role.roleName === 'GlobalAdmin';
  }

  const handleLogoutConfirmOpen = () => {
    handleCloseUserMenu();
    setIsLogoutConfirmOpen(true);
  };

  const handleViewRoleChange = () => {
    handleCloseUserMenu();
    dispatch(
      updateViewRoleAct(currentViewRole === 3 ? currentUser.role.id : 3),
    );
  };

  const handleLogoutConfirmClose = () => setIsLogoutConfirmOpen(false);

  const handleOpenUserMenu = event => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleOpenLanguageMenu = event => {
    setAnchorElLanguage(event.currentTarget);
  };

  const handleCloseLanguageMenu = () => {
    setAnchorElLanguage(null);
  };

  const handleLanguageChange = key => {
    dispatch(changeLocale(key));
  };

  const handleLogout = () => {
    handleLogoutConfirmClose();
    dispatch(resetLogin());
    dispatch(performLogoutAction());
  };

  return (
    <AppBar
      position="sticky"
      color="transparent"
      sx={{
        backgroundColor: '#FFFFFF',
        zIndex: theme => theme.zIndex.drawer + 1,
      }}
    >
      <Container maxWidth={false} sx={{ maxWidth: '100vw' }}>
        <Toolbar disableGutters sx={{ minHeight: { md: '84px' } }}>
          <Link component={RouterLink} to="/events/active">
            <Box
              component="img"
              src={Logo}
              alt=""
              sx={{
                display: { md: 'flex' },
                mr: { xs: 1, md: 2, lg: 5 },
                width: {
                  xs: '64px',
                  sm: '90px',
                  md: '154px',
                },
                py: 2,
                pr: 2,
              }}
            />
          </Link>
          <Box flexGrow={1} display="flex" alignItems="center">
            <DynamicHeaderTitle></DynamicHeaderTitle>
          </Box>
          <Box sx={{ display: 'inline-flex', alignItems: 'center' }}>
            <Hidden mdDown>
              <IconButton
                sx={{ p: 0, mr: 1 }}
                aria-label="Change language"
                color="inherit"
                onClick={handleOpenLanguageMenu}
              >
                <GTranslate />
              </IconButton>
            </Hidden>
            <IconButton sx={{ p: 0, mr: 1 }}>
              <Avatar sx={avatarProp.sx}>{avatarProp.children}</Avatar>
            </IconButton>
            <Hidden mdDown>
              <Typography component="div">
                <Box sx={{ fontWeight: 'bold' }}>{currentUser.firstName}</Box>
              </Typography>
            </Hidden>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                {anchorElUser ? <KeyboardArrowDownIcon /> : <KeyboardArrowUp />}
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={handleCloseUserMenu}>
                <Link
                  component={RouterLink}
                  underline="none"
                  to={`/users/${currentUser.id}`}
                  sx={{ flexGrow: 1 }}
                >
                  <Typography>{profileText}</Typography>
                </Link>
              </MenuItem>
              {isAdmin && currentViewRole !== 3 && (
                <>
                  <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      component={RouterLink}
                      underline="none"
                      to="/admin"
                      sx={{ flexGrow: 1 }}
                    >
                      <Typography>{manageUserTxt}</Typography>
                    </Link>
                  </MenuItem>
                  {/* <MenuItem onClick={handleCloseUserMenu}>
                    <Link
                      component={RouterLink}
                      underline="none"
                      to="/admin/sync-jobs"
                      sx={{ flexGrow: 1 }}
                    >
                      <Typography>Sync Jobs</Typography>
                    </Link>
                  </MenuItem> */}
                </>
              )}
              {isAdmin && (
                <MenuItem onClick={handleViewRoleChange}>
                  <Typography textAlign="center">
                    {currentViewRole === 3
                      ? switchRoleToAdminTxt
                      : switchRoleToOnboarderTxt}
                  </Typography>
                </MenuItem>
              )}
              <MenuItem onClick={handleLogoutConfirmOpen}>
                <Typography textAlign="center">{logoutText}</Typography>
              </MenuItem>
            </Menu>
            <Menu
              sx={{ mt: '45px' }}
              id="language-appbar"
              anchorEl={anchorElLanguage}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElLanguage)}
              onClose={handleCloseLanguageMenu}
            >
              {appLocales.map(key => (
                <MenuItem
                  key={key}
                  onClick={() => handleLanguageChange(key)}
                  selected={key === currentLocale}
                >
                  <Typography
                    sx={{ textTransform: 'uppercase' }}
                    textAlign="center"
                  >
                    {key}
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
      <Dialog
        open={isLogoutConfirmOpen}
        onClose={handleLogoutConfirmClose}
        aria-labelledby="logout-confirm-dialog-title"
        aria-describedby="logout-confirm-dialog-description"
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle id="logout-confirm-dialog-title">
          {confirmationTxt}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="logout-confirm-dialog-description">
            {logoutWarnTxt}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="outlined"
            size="small"
            onClick={handleLogoutConfirmClose}
          >
            {cancelTxt}
          </Button>
          <Button
            size="small"
            variant="contained"
            onClick={handleLogout}
            autoFocus
            color="primary"
          >
            {logoutTxt}
          </Button>
        </DialogActions>
      </Dialog>
    </AppBar>
  );
}
