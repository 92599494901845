/*
 *
 * SupporterDetail constants
 *
 */
export const REDUCER_KEY = 'SupporterDetail';
export const GET_SUPPORTER_DETAIL = 'app/SupporterDetail/GET_SUPPORTER_DETAIL';
export const GET_SUPPORTER_DETAIL_ERROR =
  'app/SupporterDetail/GET_SUPPORTER_DETAIL_ERROR';
export const GET_SUPPORTER_DETAIL_SUCCESS =
  'app/SupporterDetail/GET_SUPPORTER_DETAIL_SUCCESS';

export const UPDATE_SUPPORTER = 'app/SupporterDetail/UPDATE_SUPPORTER';
export const UPDATE_SUPPORTER_ERROR =
  'app/SupporterDetail/UPDATE_SUPPORTER_ERROR';
export const UPDATE_SUPPORTER_SUCCESS =
  'app/SupporterDetail/UPDATE_SUPPORTER_SUCCESS';

export const DELETE_SUPPORTER = 'app/SupporterDetail/DELETE_SUPPORTER';
export const DELETE_SUPPORTER_ERROR =
  'app/SupporterDetail/DELETE_SUPPORTER_ERROR';
export const DELETE_SUPPORTER_SUCCESS =
  'app/SupporterDetail/DELETE_SUPPORTER_SUCCESS';

export const TRANSFER_MEAL = 'app/SupporterDetail/TRANSFER_MEAL';
export const TRANSFER_MEAL_ERROR = 'app/SupporterDetail/TRANSFER_MEAL_ERROR';
export const TRANSFER_MEAL_SUCCESS =
  'app/SupporterDetail/TRANSFER_MEAL_SUCCESS';

export const REMOVE_MEAL = 'app/SupporterDetail/REMOVE_MEAL';
export const REMOVE_MEAL_ERROR = 'app/SupporterDetail/REMOVE_MEAL_ERROR';
export const REMOVE_MEAL_SUCCESS = 'app/SupporterDetail/REMOVE_MEAL_SUCCESS';

export const RESET_SUPPORTER_DETAIL =
  'app/SupporterDetail/RESET_SUPPORTER_DETAIL';
