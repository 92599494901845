import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { ReduxRouter } from '@lagunovsky/redux-react-router';
import { shouldPolyfill } from '@formatjs/intl-locale/should-polyfill';

import configureStore from './configureStore';
import history from './utils/history';

import '@fontsource/manrope/300.css';
import '@fontsource/manrope/400.css';
import '@fontsource/manrope/500.css';
import '@fontsource/manrope/700.css';

import './index.css';
import reportWebVitals from './reportWebVitals';
import App from './components/App';
import setupInterceptors from './api/interceptors';
import LanguageProviderManager from './managers/LanguageProviderManager';
import { LanguageProvider } from './components/LanguageProvider';
// Import i18n messages
const { translationMessages } = require('./i18n');

// Create redux store with history
const store = configureStore(history);

const MOUNT_NODE = document.getElementById('app');

const render = messages => {
  const root = ReactDOM.createRoot(MOUNT_NODE);
  root.render(
    <Provider store={store}>
      <LanguageProviderManager>
        <LanguageProvider messages={messages}>
          <HelmetProvider>
            <ReduxRouter history={history}>
              {/* <StrictMode> */}
              <App></App>
              {/* </StrictMode> */}
            </ReduxRouter>
          </HelmetProvider>
        </LanguageProvider>
      </LanguageProviderManager>
    </Provider>,
  );
  setupInterceptors(store);
};

// Chunked polyfill for browsers without Intl support
(async () => {
  if (shouldPolyfill()) {
    await import('@formatjs/intl-locale/polyfill');
    render(translationMessages);
  } else {
    render(translationMessages);
  }
})();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
