/*
 *
 * Login reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  loginAction,
  loginErrorAction,
  loginSuccessAction,
  resetLogin,
} from './actions';

export const initialState = {
  // 0 - idle, 1 -inprogress, 2 - succcess, 3
  loginState: 0,
  error: '',
};

/* eslint-disable default-case, no-param-reassign */
const loginSlice = createSlice({
  name: 'Login',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(loginAction, state => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.loginState = 1;
      })
      .addCase(loginSuccessAction, state => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.loginState = 2;
        state.error = '';
      })
      .addCase(loginErrorAction, (state, { payload }) => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.loginState = 3;
        state.error = payload;
      })
      .addCase(resetLogin, state => {
        // Redux Toolkit allows us to write "mutating" logic in reducers. It
        // doesn't actually mutate the state because it uses the immer library,
        // which detects changes to a "draft state" and produces a brand new
        // immutable state based off those changes
        state.loginState = 0;
        state.error = '';
      });
  },
});

export default loginSlice.reducer;
