/*
 *
 * TeamTags actions
 *
 */

import { createAction } from '@reduxjs/toolkit';

import {
  CREATE_TEAM_TAGS,
  CREATE_TEAM_TAGS_ERROR,
  CREATE_TEAM_TAGS_SUCCESS,
  DELETE_TEAM_TAGS,
  DELETE_TEAM_TAGS_ERROR,
  DELETE_TEAM_TAGS_SUCCESS,
  GET_TEAM_TAGS_LIST,
  GET_TEAM_TAGS_LIST_ERROR,
  GET_TEAM_TAGS_LIST_SUCCESS,
  RESET_GET_TEAM_TAGS_LIST,
  SET_ACTIVE_TAGS_ITEM,
  UPDATE_TEAM_TAGS,
  UPDATE_TEAM_TAGS_ERROR,
  UPDATE_TEAM_TAGS_SUCCESS,
} from './constants';

export const getTeamTagsList = createAction(GET_TEAM_TAGS_LIST);
export const getTeamTagsListError = createAction(GET_TEAM_TAGS_LIST_ERROR);
export const getTeamTagsListSuccess = createAction(GET_TEAM_TAGS_LIST_SUCCESS);
export const resetGetTeamTagsList = createAction(RESET_GET_TEAM_TAGS_LIST);
export const deleteTeamTags = createAction(DELETE_TEAM_TAGS);
export const deleteTeamTagsError = createAction(DELETE_TEAM_TAGS_ERROR);
export const deleteTeamTagsSuccess = createAction(DELETE_TEAM_TAGS_SUCCESS);
export const updateTeamTags = createAction(UPDATE_TEAM_TAGS);
export const updateTeamTagsError = createAction(UPDATE_TEAM_TAGS_ERROR);
export const updateTeamTagsSuccess = createAction(UPDATE_TEAM_TAGS_SUCCESS);
export const setActiveNoteItem = createAction(SET_ACTIVE_TAGS_ITEM);
export const createTeamTags = createAction(CREATE_TEAM_TAGS);
export const createTeamTagsError = createAction(CREATE_TEAM_TAGS_ERROR);
export const createTeamTagsSuccess = createAction(CREATE_TEAM_TAGS_SUCCESS);
