/*
 *
 * MemberTags reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createMemberTagsAct,
  createMemberTagsErrorAct,
  createMemberTagsSuccessAct,
  deleteMemberTagsAct,
  deleteMemberTagsErrorAct,
  deleteMemberTagsSuccessAct,
  getMemberTagsListAct,
  getMemberTagsListErrorAct,
  getMemberTagsListSuccessAct,
  resetGetMemberTagsListAct,
  setActiveTagItemAct,
  updateMemberTagsAct,
  updateMemberTagsErrorAct,
  updateMemberTagsSuccessAct,
} from './actions';

import tagsEntityAdapter from './entity-adapter';

export const initialState = tagsEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  activeId: '',
  activeState: '',
  activeAction: 0,
  activeError: '',
  createState: 0,
  createError: '',
});

/* eslint-disable default-case, no-param-reassign */
const memberTagsSlice = createSlice({
  name: 'MemberTags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getMemberTagsListAct, (state, { payload }) => {
        state.loadingState = 1;
        state.error = '';
        if (payload.params && payload.params.page === 1) {
          tagsEntityAdapter.removeAll(state);
        }
      })
      .addCase(getMemberTagsListErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getMemberTagsListSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        tagsEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(setActiveTagItemAct, (state, { payload }) => {
        const { id, actionName, actionState, actionError } = payload;
        state.activeAction = actionName;
        state.activeState = actionState;
        state.activeError = actionError;
        state.activeId = id;
      })
      .addCase(deleteMemberTagsAct, state => {
        state.activeState = 1;
        state.activeAction = 'Delete';
        state.activeError = '';
      })
      .addCase(deleteMemberTagsErrorAct, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(deleteMemberTagsSuccessAct, (state, { payload }) => {
        const { id } = payload;
        state.activeState = 2;
        tagsEntityAdapter.removeOne(state, id);
      })
      .addCase(updateMemberTagsAct, state => {
        state.activeState = 1;
        state.activeAction = 'Update';
        state.activeError = '';
      })
      .addCase(updateMemberTagsErrorAct, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(updateMemberTagsSuccessAct, (state, { payload }) => {
        const { id, name, updatedAt } = payload;
        state.activeState = 2;
        state.error = '';
        if (id && state.entities[id]) {
          tagsEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              updatedAt,
            },
          });
        }
      })
      .addCase(createMemberTagsAct, state => {
        state.createState = 1;
        state.createError = '';
      })
      .addCase(createMemberTagsErrorAct, (state, { payload }) => {
        state.createState = 3;
        state.createError = payload;
      })
      .addCase(createMemberTagsSuccessAct, (state, { payload }) => {
        state.createState = 2;
        tagsEntityAdapter.addOne(state, payload);
      })
      .addCase(resetGetMemberTagsListAct, () => initialState);
  },
});

export default memberTagsSlice.reducer;
