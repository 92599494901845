import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the eventTeamDetail state domain
 */

const selectEventTeamDetailDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by EventTeamDetail
 */

const makeSelectEventTeamDetail = () =>
  createSelector(selectEventTeamDetailDomain, substate => substate);

const makeSelectTeam = () =>
  createSelector(selectEventTeamDetailDomain, substate => substate.team);

const makeSelectTeamName = () =>
  createSelector(selectEventTeamDetailDomain, substate =>
    substate.team ? substate.team.name : '',
  );

const makeSelectTeamId = () =>
  createSelector(selectEventTeamDetailDomain, substate =>
    substate.team ? substate.team.id : '',
  );

const makeSelectTeamRegistrationDate = () =>
  createSelector(selectEventTeamDetailDomain, substate =>
    substate.team ? substate.team.registrationDate : '',
  );

const makeSelectTeamBibNumber = () =>
  createSelector(selectEventTeamDetailDomain, substate =>
    substate.team ? substate.team.bibNumber : '',
  );

const makeSelectLoadingState = () =>
  createSelector(
    selectEventTeamDetailDomain,
    substate => substate.loadingState,
  );

const makeSelectBibState = () =>
  createSelector(selectEventTeamDetailDomain, substate => substate.setBibState);

const makeSelectTeamStatusState = () =>
  createSelector(selectEventTeamDetailDomain, substate => substate.statusState);

const makeSelectStartTimeState = () =>
  createSelector(
    selectEventTeamDetailDomain,
    substate => substate.startTimeState,
  );

const makeSelectError = () =>
  createSelector(selectEventTeamDetailDomain, substate => substate.error);

const makeSelectTeamStatus = () =>
  createSelector(selectEventTeamDetailDomain, substate =>
    substate.team ? substate.team.status : '',
  );

const makeSelectMemberCertState = () =>
  createSelector(
    selectEventTeamDetailDomain,
    substate => substate.memberCertState,
  );

const makeSelectMemberParentConsentState = () =>
  createSelector(
    selectEventTeamDetailDomain,
    substate => substate.memberParentConsentState,
  );

const makeSelectTagState = () =>
  createSelector(selectEventTeamDetailDomain, substate => substate.tagState);

const makeSelectUserAssign = () =>
  createSelector(selectEventTeamDetailDomain, substate => substate.userAssign);

const makeSelectWelcomeStatusState = () =>
  createSelector(
    selectEventTeamDetailDomain,
    substate => substate.welcomeStatusState,
  );

export default makeSelectEventTeamDetail;
export {
  selectEventTeamDetailDomain,
  makeSelectTeam,
  makeSelectLoadingState,
  makeSelectError,
  makeSelectTeamName,
  makeSelectBibState,
  makeSelectTeamRegistrationDate,
  makeSelectTeamBibNumber,
  makeSelectStartTimeState,
  makeSelectTeamStatus,
  makeSelectTeamId,
  makeSelectTeamStatusState,
  makeSelectMemberCertState,
  makeSelectTagState,
  makeSelectMemberParentConsentState,
  makeSelectUserAssign,
  makeSelectWelcomeStatusState,
};
