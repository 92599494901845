/*
 *
 * MemberDetail constants
 *
 */
export const REDUCER_KEY = 'MemberDetail';

export const GET_MEMBER_DETAIL = 'app/MemberDetail/GET_MEMBER_DETAIL';
export const GET_MEMBER_DETAIL_ERROR =
  'app/MemberDetail/GET_MEMBER_DETAIL_ERROR';
export const GET_MEMBER_DETAIL_SUCCESS =
  'app/MemberDetail/GET_MEMBER_DETAIL_SUCCESS';

export const UPDATE_MEDICAL_CERT = 'app/MemberDetail/UPDATE_MEDICAL_CERT';
export const UPDATE_MEDICAL_CERT_ERROR =
  'app/MemberDetail/UPDATE_MEDICAL_CERT_ERROR';
export const UPDATE_MEDICAL_CERT_SUCCESS =
  'app/MemberDetail/UPDATE_MEDICAL_CERT_SUCCESS';

export const UPDATE_PARENTAL_CONSENT =
  'app/MemberDetail/UPDATE_PARENTAL_CONSENT';
export const UPDATE_PARENTAL_CONSENT_ERROR =
  'app/MemberDetail/UPDATE_PARENTAL_CONSENT_ERROR';
export const UPDATE_PARENTAL_CONSENT_SUCCESS =
  'app/MemberDetail/UPDATE_PARENTAL_CONSENT_SUCCESS';

export const UPDATE_TSHIRT_SIZE = 'app/MemberDetail/UPDATE_TSHIRT_SIZE';
export const UPDATE_TSHIRT_SIZE_ERROR =
  'app/MemberDetail/UPDATE_TSHIRT_SIZE_ERROR';
export const UPDATE_TSHIRT_SIZE_SUCCESS =
  'app/MemberDetail/UPDATE_TSHIRT_SIZE_SUCCESS';

export const UPDATE_TAG_STATE = 'app/MemberDetail/UPDATE_TAG_STATE';
export const UPDATE_TAG_STATE_ERROR = 'app/MemberDetail/UPDATE_TAG_STATE_ERROR';
export const UPDATE_TAG_STATE_SUCCESS =
  'app/MemberDetail/UPDATE_TAG_STATE_SUCCESS';

export const TRANSFER_MEAL = 'app/MemberDetail/TRANSFER_MEAL';
export const TRANSFER_MEAL_ERROR = 'app/MemberDetail/TRANSFER_MEAL_ERROR';
export const TRANSFER_MEAL_SUCCESS = 'app/MemberDetail/TRANSFER_MEAL_SUCCESS';

export const REMOVE_MEAL = 'app/MemberDetail/REMOVE_MEAL';
export const REMOVE_MEAL_ERROR = 'app/MemberDetail/REMOVE_MEAL_ERROR';
export const REMOVE_MEAL_SUCCESS = 'app/MemberDetail/REMOVE_MEAL_SUCCESS';

export const RESET_MEMBER_DETAIL = 'app/MemberDetail/RESET_MEMBER_DETAIL';
