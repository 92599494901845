/*
 *
 * Auth actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_CURRENT_USER,
  GET_CURRENT_USER_ERROR,
  GET_CURRENT_USER_SUCCESS,
  PERFORM_LOGOUT,
  PERFORM_LOGOUT_SUCCESS,
  RESET_AUTH_STATE,
  SESSION_EXPIRED,
  SET_AUTH,
  SET_PAGE_TITLE,
  UPDATE_VIEW_ROLE,
} from './constants';

export const setAuthAction = createAction(SET_AUTH);
export const getCurrentUserAction = createAction(GET_CURRENT_USER);
export const getCurrentUserSuccessAction = createAction(
  GET_CURRENT_USER_SUCCESS,
);
export const getCurrentUserFailedAction = createAction(GET_CURRENT_USER_ERROR);

export const performLogoutAction = createAction(PERFORM_LOGOUT);
export const performLogoutSuccessAction = createAction(PERFORM_LOGOUT_SUCCESS);
export const sessionExpiredAction = createAction(SESSION_EXPIRED);
export const setPageTitle = createAction(SET_PAGE_TITLE);
export const updateViewRoleAct = createAction(UPDATE_VIEW_ROLE);
export const resetAuthStateAction = createAction(RESET_AUTH_STATE);
