/* eslint-disable react/prop-types */
/**
 *
 * DateBadge
 *
 */

import React, { memo } from 'react';

import { Paper, Stack, Typography } from '@mui/material';
import dayjs from 'dayjs';

export function DateBadge({ date }) {
  const dateObj = dayjs(date);
  const monthName = dateObj.format('MMM');
  const dayName = dateObj.format('DD');

  return (
    <Paper
      variant="outlined"
      sx={{
        px: 1,
        backgroundColor: theme => theme.palette.primary.main,
      }}
    >
      <Stack>
        <Typography
          textAlign="center"
          sx={{ color: theme => theme.palette.primary.contrastText }}
        >
          {dayName}
        </Typography>
        <Typography
          textAlign="center"
          variant="caption"
          sx={{
            color: theme => theme.palette.primary.contrastText,
            textTransform: 'uppercase',
          }}
        >
          {monthName}
        </Typography>
      </Stack>
    </Paper>
  );
}

export default memo(DateBadge);
