/*
 *
 * VolunteerDetail constants
 *
 */
export const REDUCER_KEY = 'VolunteerDetail';
export const GET_VOLUNTEER_DETAIL = 'app/VolunteerDetail/GET_VOLUNTEER_DETAIL';
export const GET_VOLUNTEER_DETAIL_ERROR =
  'app/VolunteerDetail/GET_VOLUNTEER_DETAIL_ERROR';
export const GET_VOLUNTEER_DETAIL_SUCCESS =
  'app/VolunteerDetail/GET_VOLUNTEER_DETAIL_SUCCESS';

export const UPDATE_MISSION_STATE = 'app/VolunteerDetail/UPDATE_MISSION_STATE';
export const UPDATE_MISSION_STATE_ERROR =
  'app/VolunteerDetail/UPDATE_MISSION_STATE_ERROR';
export const UPDATE_MISSION_STATE_SUCCESS =
  'app/VolunteerDetail/UPDATE_MISSION_STATE_SUCCESS';

export const UPDATE_TAG_STATE = 'app/VolunteerDetail/UPDATE_TAG_STATE';
export const UPDATE_TAG_STATE_ERROR =
  'app/VolunteerDetail/UPDATE_TAG_STATE_ERROR';
export const UPDATE_TAG_STATE_SUCCESS =
  'app/VolunteerDetail/UPDATE_TAG_STATE_SUCCESS';

export const UPDATE_VERIFY_STATE = 'app/VolunteerDetail/UPDATE_VERIFY_STATE';
export const UPDATE_VERIFY_STATE_ERROR =
  'app/VolunteerDetail/UPDATE_VERIFY_STATE_ERROR';
export const UPDATE_VERIFY_STATE_SUCCESS =
  'app/VolunteerDetail/UPDATE_VERIFY_STATE_SUCCESS';

export const UPDATE_VERIFY_LICENSE_STATE =
  'app/VolunteerDetail/UPDATE_VERIFY_LICENSE_STATE';
export const UPDATE_VERIFY_LICENSE_STATE_ERROR =
  'app/VolunteerDetail/UPDATE_VERIFY_LICENSE_STATE_ERROR';
export const UPDATE_VERIFY_LICENSE_STATE_SUCCESS =
  'app/VolunteerDetail/UPDATE_VERIFY_LICENSE_STATE_SUCCESS';

export const ASSIGN_USER = 'app/VolunteerDetail/ASSIGN_USER';
export const ASSIGN_USER_ERROR = 'app/VolunteerDetail/ASSIGN_USER_ERROR';
export const ASSIGN_USER_SUCCESS = 'app/VolunteerDetail/ASSIGN_USER_SUCCESS';

export const ASSIGN_GROUP = 'app/VolunteerDetail/ASSIGN_GROUP';
export const ASSIGN_GROUP_ERROR = 'app/VolunteerDetail/ASSIGN_GROUP_ERROR';
export const ASSIGN_GROUP_SUCCESS = 'app/VolunteerDetail/ASSIGN_GROUP_SUCCESS';

export const UPDATE_VOLUNTEER = 'app/VolunteerDetail/UPDATE_VOLUNTEER';
export const UPDATE_VOLUNTEER_ERROR =
  'app/VolunteerDetail/UPDATE_VOLUNTEER_ERROR';
export const UPDATE_VOLUNTEER_SUCCESS =
  'app/VolunteerDetail/UPDATE_VOLUNTEER_SUCCESS';

export const UPDATE_PARENTAL_CONSENT_STATE =
  'app/VolunteerDetail/UPDATE_PARENTAL_CONSENT_STATE';
export const UPDATE_PARENTAL_CONSENT_STATE_ERROR =
  'app/VolunteerDetail/UPDATE_PARENTAL_CONSENT_STATE_ERROR';
export const UPDATE_PARENTAL_CONSENT_STATE_SUCCESS =
  'app/VolunteerDetail/UPDATE_PARENTAL_CONSENT_STATE_SUCCESS';

export const UPDATE_LINKED_VOLUNTEER =
  'app/VolunteerDetail/UPDATE_LINKED_VOLUNTEER';
export const UPDATE_LINKED_VOLUNTEER_ERROR =
  'app/VolunteerDetail/UPDATE_LINKED_VOLUNTEER_ERROR';
export const UPDATE_LINKED_VOLUNTEER_SUCCESS =
  'app/VolunteerDetail/UPDATE_LINKED_VOLUNTEER_SUCCESS';

export const DELETE_VOLUNTEER = 'app/VolunteerDetail/DELETE_VOLUNTEER';
export const DELETE_VOLUNTEER_ERROR =
  'app/VolunteerDetail/DELETE_VOLUNTEER_ERROR';
export const DELETE_VOLUNTEER_SUCCESS =
  'app/VolunteerDetail/DELETE_VOLUNTEER_SUCCESS';

export const RESET_VOLUNTEER_DETAIL =
  'app/VolunteerDetail/RESET_VOLUNTEER_DETAIL';
