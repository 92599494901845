/*
 *
 * EventsPage actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_EVENT_LIST,
  GET_EVENT_LIST_ERROR,
  GET_EVENT_LIST_SUCCESS,
  RESET_GET_EVENT_LIST,
} from './constants';

export const getEventListAction = createAction(GET_EVENT_LIST);
export const getEventListActionError = createAction(GET_EVENT_LIST_ERROR);
export const getEventListActionSuccess = createAction(GET_EVENT_LIST_SUCCESS);
export const resetEventListAction = createAction(RESET_GET_EVENT_LIST);
