/*
 *
 * VolunteerDetail reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  assignGroup,
  assignGroupError,
  assignGroupSuccess,
  assignUser,
  assignUserError,
  assignUserSuccess,
  getVolunteerDetail,
  getVolunteerDetailError,
  getVolunteerDetailSuccess,
  resetVolunteerDetail,
  updateMissionState,
  updateMissionStateError,
  updateMissionStateSuccess,
  updateTagState,
  updateTagStateError,
  updateTagStateSuccess,
  updateVerifyLicenseState,
  updateVerifyLicenseStateError,
  updateVerifyLicenseStateSuccess,
  updateVerifyState,
  updateVerifyStateError,
  updateVerifyStateSuccess,
  updateVolunteer,
  updateVolunteerError,
  updateVolunteerSuccess,
  updateParentalConsentState,
  updateParentalConsentStateError,
  updateParentalConsentStateSuccess,
  updateLinkedVolunteer,
  updateLinkedVolunteerError,
  updateLinkedVolunteerSuccess,
  deleteVolunteerAct,
  deleteVolunteerErrorAct,
  deleteVolunteerSuccessAct,
} from './actions';

export const initialState = {
  loadingState: 0,
  error: '',
  volunteer: null,
  missionState: 0,
  linkedState: 0,
  tagState: 0,
  canDriveState: 0,
  userAssign: 0,
  groupAssign: 0,
  verifyState: 0,
  updateState: 0,
  parentalConsentState: 0,
  linkVolunteerState: 0,
  deleteState: 0,
};

/* eslint-disable default-case, no-param-reassign */
const volunteerDetailSlice = createSlice({
  name: 'VolunteerDetail',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVolunteerDetail, state => {
        state.loadingState = 1;
        state.error = '';
      })
      .addCase(getVolunteerDetailError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getVolunteerDetailSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.volunteer = payload;
      })
      .addCase(updateMissionState, (state, { payload }) => {
        const { data } = payload;
        const { missionId } = data;
        const { volunteer } = state;
        if (volunteer.missions) {
          const mission = volunteer.missions.find(mis => mis.id === missionId);
          if (mission) {
            mission.loadingState = 1;
          }
        }
        state.error = '';
        state.missionState = 1;
      })
      .addCase(updateMissionStateError, (state, { payload }) => {
        const { missionId, error, isAssigned } = payload;
        const { volunteer } = state;
        if (volunteer.missions) {
          const mission = volunteer.missions.find(mis => mis.id === missionId);
          if (mission) {
            mission.loadingState = 3;
            mission.status.isAssigned = isAssigned;
          }
        }
        state.missionState = 3;
        state.error = error;
      })
      .addCase(updateMissionStateSuccess, (state, { payload }) => {
        const { volunteerMissionId: missionId, isAssigned } = payload;
        const { volunteer } = state;
        if (volunteer.missions) {
          const mission = volunteer.missions.find(mis => mis.id === missionId);
          if (mission) {
            mission.loadingState = 0;
            mission.status.isAssigned = isAssigned;
          }
        }
        state.missionState = 2;
        state.error = '';
      })
      .addCase(updateTagState, (state, { payload }) => {
        const { data } = payload;
        const { tagId } = data;
        const { volunteer } = state;
        if (volunteer.tags) {
          const tag = volunteer.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 1;
          }
        }
        state.error = '';
        state.tagState = 1;
      })
      .addCase(updateTagStateError, (state, { payload }) => {
        const { tagId, error, isAssigned } = payload;
        const { volunteer } = state;
        if (volunteer.tags) {
          const tag = volunteer.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 3;
            tag.status.active = isAssigned;
          }
        }
        state.tagState = 3;
        state.error = error;
      })
      .addCase(updateTagStateSuccess, (state, { payload }) => {
        const { volunteerTagId: tagId, active } = payload;
        const { volunteer } = state;
        if (volunteer.tags) {
          const tag = volunteer.tags.find(tg => tg.id === tagId);
          if (tag) {
            tag.loadingState = 0;
            tag.status.active = active;
          }
        }
        state.tagState = 2;
        state.error = '';
      })
      .addCase(assignUser, state => {
        state.error = '';
        state.userAssign = 1;
      })
      .addCase(assignUserError, (state, { payload }) => {
        state.error = payload;
        state.userAssign = 3;
      })
      .addCase(assignUserSuccess, (state, { payload }) => {
        state.volunteer = { ...state.volunteer, ...payload };
        state.userAssign = 2;
        state.error = '';
      })
      .addCase(assignGroup, state => {
        state.error = '';
        state.groupAssign = 1;
      })
      .addCase(assignGroupError, (state, { payload }) => {
        state.error = payload;
        state.groupAssign = 3;
      })
      .addCase(assignGroupSuccess, (state, { payload }) => {
        state.groupAssign = 2;
        state.error = '';
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateVerifyLicenseState, state => {
        state.error = '';
        state.canDriveState = 1;
      })
      .addCase(updateVerifyLicenseStateError, (state, { payload }) => {
        state.error = payload;
        state.canDriveState = 3;
      })
      .addCase(updateVerifyLicenseStateSuccess, (state, { payload }) => {
        state.canDriveState = 2;
        state.error = '';
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateVerifyState, state => {
        state.error = '';
        state.verifyState = 1;
      })
      .addCase(updateVerifyStateError, (state, { payload }) => {
        state.error = payload;
        state.verifyState = 3;
      })
      .addCase(updateVerifyStateSuccess, (state, { payload }) => {
        state.verifyState = 2;
        state.error = '';
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateVolunteer, state => {
        state.error = '';
        state.updateState = 1;
      })
      .addCase(updateVolunteerError, (state, { payload }) => {
        state.error = payload;
        state.updateState = 3;
      })
      .addCase(updateVolunteerSuccess, (state, { payload }) => {
        state.updateState = 2;
        state.error = '';
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateParentalConsentState, state => {
        state.error = '';
        state.parentalConsentState = 1;
      })
      .addCase(updateParentalConsentStateError, (state, { payload }) => {
        state.error = payload;
        state.parentalConsentState = 3;
      })
      .addCase(updateParentalConsentStateSuccess, (state, { payload }) => {
        state.parentalConsentState = 2;
        state.error = '';
        state.volunteer = { ...state.volunteer, ...payload };
      })
      .addCase(updateLinkedVolunteer, state => {
        state.error = '';
        state.linkVolunteerState = 1;
      })
      .addCase(updateLinkedVolunteerError, (state, { payload }) => {
        state.error = payload;
        state.linkVolunteerState = 3;
      })
      .addCase(updateLinkedVolunteerSuccess, (state, { payload }) => {
        state.linkVolunteerState = 2;
        state.error = '';
        state.volunteer = {
          ...state.volunteer,
          ...payload,
        };
      })
      .addCase(deleteVolunteerAct, state => {
        state.error = '';
        state.deleteState = 1;
      })
      .addCase(deleteVolunteerErrorAct, (state, { payload }) => {
        state.error = payload;
        state.deleteState = 3;
      })
      .addCase(deleteVolunteerSuccessAct, state => {
        state.deleteState = 2;
        state.error = '';
      })
      .addCase(resetVolunteerDetail, () => initialState);
  },
});

export default volunteerDetailSlice.reducer;
