import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter({});

export const {
  selectById: selectNoteById,
  selectIds: selectNoteIds,
  selectAll: selectAllNote,
  selectEntities: selectAllNoteEntities,
} = adapter.getSelectors();

export default adapter;
