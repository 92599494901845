import { createManager } from 'redux-injectors';

import reducer from './reducer';

import saga from './saga';

import { REDUCER_KEY } from './constants';

const AdminDashboardManager = createManager({
  name: 'AdminDashboardManager',
  key: REDUCER_KEY,
  reducer,
  saga,
});

export default AdminDashboardManager;
