import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the auth state domain
 */

const selectAuthDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Auth
 */

const makeSelectAuth = () =>
  createSelector(selectAuthDomain, substate => substate);

const makeSelectAuthToken = () =>
  createSelector(selectAuthDomain, substate => substate.token);

const makeSelectLoggedInState = () =>
  createSelector(selectAuthDomain, substate => substate.loggedInState);

const makeSelectUser = () =>
  createSelector(selectAuthDomain, substate => substate.user);

const makeSelectPageTitle = () =>
  createSelector(selectAuthDomain, substate => substate.pageTitle);

const makeSelectViewRole = () =>
  createSelector(selectAuthDomain, substate => substate.viewRole);

const makeSelectHasSupporterModule = () =>
  createSelector(selectAuthDomain, substate => substate.hasSupporterModule);

const makeSelectHasVolunteerModule = () =>
  createSelector(selectAuthDomain, substate => substate.hasVolunteerModule);

const makeSelectRoleId = () =>
  createSelector(makeSelectUser(), user => {
    if (user && user.role) {
      return user.role.id;
    }
    return null;
  });

export default makeSelectAuth;
export {
  selectAuthDomain,
  makeSelectAuthToken,
  makeSelectLoggedInState,
  makeSelectUser,
  makeSelectPageTitle,
  makeSelectRoleId,
  makeSelectViewRole,
  makeSelectHasSupporterModule,
  makeSelectHasVolunteerModule,
};
