import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter({});

export const {
  selectById: selectGroupById,
  selectIds: selectGroupIds,
  selectAll: selectAllGroup,
} = adapter.getSelectors();

export default adapter;
