import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the login state domain
 */

const selectLoginDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by Login
 */

const makeSelectLogin = () =>
  createSelector(selectLoginDomain, substate => substate);

const makeSelectLoginState = () =>
  createSelector(selectLoginDomain, substate => substate.loginState);

const makeSelectLoginError = () =>
  createSelector(selectLoginDomain, substate => substate.error);

export default makeSelectLogin;
export { makeSelectLoginState, makeSelectLoginError };
