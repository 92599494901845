/*
 *
 * VolunteerTags reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import {
  createVolunteerTags,
  createVolunteerTagsError,
  createVolunteerTagsSuccess,
  deleteVolunteerTags,
  deleteVolunteerTagsError,
  deleteVolunteerTagsSuccess,
  getVolunteerTagsList,
  getVolunteerTagsListError,
  getVolunteerTagsListSuccess,
  resetGetVolunteerTagsList,
  setActiveNoteItem,
  updateVolunteerTags,
  updateVolunteerTagsError,
  updateVolunteerTagsSuccess,
} from './actions';

import notesEntityAdapter from './entity-adapter';

export const initialState = notesEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  activeId: '',
  activeState: '',
  activeAction: 0,
  activeError: '',
  createState: 0,
  createError: '',
});

/* eslint-disable default-case, no-param-reassign */
const volunteerTagsSlice = createSlice({
  name: 'VolunteerTags',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getVolunteerTagsList, (state, { payload }) => {
        state.loadingState = 1;
        state.error = '';
        if (payload.params && payload.params.page === 1) {
          notesEntityAdapter.removeAll(state);
        }
      })
      .addCase(getVolunteerTagsListError, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getVolunteerTagsListSuccess, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        notesEntityAdapter.upsertMany(state, payload.rows);
        state.totalPages = payload.totalPages;
        state.count = payload.count;
      })
      .addCase(setActiveNoteItem, (state, { payload }) => {
        const { id, actionName, actionState, actionError } = payload;
        state.activeAction = actionName;
        state.activeState = actionState;
        state.activeError = actionError;
        state.activeId = id;
      })
      .addCase(deleteVolunteerTags, state => {
        state.activeState = 1;
        state.activeAction = 'Delete';
        state.activeError = '';
      })
      .addCase(deleteVolunteerTagsError, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(deleteVolunteerTagsSuccess, (state, { payload }) => {
        const { id } = payload;
        state.activeState = 2;
        notesEntityAdapter.removeOne(state, id);
      })
      .addCase(updateVolunteerTags, state => {
        state.activeState = 1;
        state.activeAction = 'Update';
        state.activeError = '';
      })
      .addCase(updateVolunteerTagsError, (state, { payload }) => {
        state.activeState = 3;
        state.activeError = payload;
      })
      .addCase(updateVolunteerTagsSuccess, (state, { payload }) => {
        const { id, name, updatedAt } = payload;
        state.activeState = 2;
        state.error = '';
        if (id && state.entities[id]) {
          notesEntityAdapter.updateOne(state, {
            id,
            changes: {
              name,
              updatedAt,
            },
          });
        }
      })
      .addCase(createVolunteerTags, state => {
        state.createState = 1;
        state.createError = '';
      })
      .addCase(createVolunteerTagsError, (state, { payload }) => {
        state.createState = 3;
        state.createError = payload;
      })
      .addCase(createVolunteerTagsSuccess, (state, { payload }) => {
        state.createState = 2;
        notesEntityAdapter.addOne(state, payload);
      })
      .addCase(resetGetVolunteerTagsList, state => {
        state.loadingState = 0;
        state.error = '';
        notesEntityAdapter.removeAll(state);
        state.totalPages = 0;
        state.count = 0;
        state.activeId = '';
        state.activeState = '';
        state.activeAction = 0;
        state.activeError = '';
        state.createState = 0;
        state.createError = '';
      });
  },
});

export default volunteerTagsSlice.reducer;
