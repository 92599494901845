/*
 *
 * SupporterList actions
 *
 */

import { createAction } from '@reduxjs/toolkit';

import {
  GET_SUPPORTER_LIST,
  GET_SUPPORTER_LIST_ERROR,
  GET_SUPPORTER_LIST_SUCCESS,
  GET_UNIQUE_COUNTRIES,
  GET_UNIQUE_COUNTRIES_ERROR,
  GET_UNIQUE_COUNTRIES_SUCCESS,
  GET_UNIQUE_ZIPCODES,
  GET_UNIQUE_ZIPCODES_ERROR,
  GET_UNIQUE_ZIPCODES_SUCCESS,
  RESET_GET_SUPPORTER_LIST,
  UPDATE_SUPPORTER,
  UPDATE_SUPPORTER_ERROR,
  UPDATE_SUPPORTER_SUCCESS,
} from './constants';

export const getSupporterListAct = createAction(GET_SUPPORTER_LIST);
export const getSupporterListErrorAct = createAction(GET_SUPPORTER_LIST_ERROR);
export const getSupporterListSuccessAct = createAction(
  GET_SUPPORTER_LIST_SUCCESS,
);

export const getUniqueZipCodesAct = createAction(GET_UNIQUE_ZIPCODES);
export const getUniqueZipCodesErrorAct = createAction(
  GET_UNIQUE_ZIPCODES_ERROR,
);
export const getUniqueZipCodesSuccessAct = createAction(
  GET_UNIQUE_ZIPCODES_SUCCESS,
);

export const getUniqueCountriesAct = createAction(GET_UNIQUE_COUNTRIES);
export const getUniqueCountriesErrorAct = createAction(
  GET_UNIQUE_COUNTRIES_ERROR,
);
export const getUniqueCountriesSuccessAct = createAction(
  GET_UNIQUE_COUNTRIES_SUCCESS,
);

export const updateSupporterDetailAct = createAction(UPDATE_SUPPORTER);
export const updateSupporterDetailErrorAct = createAction(
  UPDATE_SUPPORTER_ERROR,
);
export const updateSupporterDetailSuccessAct = createAction(
  UPDATE_SUPPORTER_SUCCESS,
);

export const resetSupporterAct = createAction(RESET_GET_SUPPORTER_LIST);
