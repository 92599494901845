/*
 *
 * AdminDashboard actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  ACTIVATE_USER,
  ACTIVATE_USER_ERROR,
  ACTIVATE_USER_SUCCESS,
  CREATE_USER,
  CREATE_USER_ERROR,
  CREATE_USER_SUCCESS,
  DEACTIVATE_USER,
  DEACTIVATE_USER_ERROR,
  DEACTIVATE_USER_SUCCESS,
  GET_USERS,
  GET_USERS_ERROR,
  GET_USERS_SUCCESS,
  RESET_ADMIN,
  TRANSFER_OWNER,
  TRANSFER_OWNER_ERROR,
  TRANSFER_OWNER_SUCCESS,
} from './constants';

export const getUsersAct = createAction(GET_USERS);
export const getUsersErrorAct = createAction(GET_USERS_ERROR);
export const getUsersSuccessAct = createAction(GET_USERS_SUCCESS);

export const createUserAct = createAction(CREATE_USER);
export const createUserErrorAct = createAction(CREATE_USER_ERROR);
export const createUserSuccessAct = createAction(CREATE_USER_SUCCESS);

export const deactivateUserAct = createAction(DEACTIVATE_USER);
export const deactivateUserErrorAct = createAction(DEACTIVATE_USER_ERROR);
export const deactivateUserSuccessAct = createAction(DEACTIVATE_USER_SUCCESS);

export const activateUserAct = createAction(ACTIVATE_USER);
export const activateUserErrorAct = createAction(ACTIVATE_USER_ERROR);
export const activateUserSuccessAct = createAction(ACTIVATE_USER_SUCCESS);

export const transferOwnerAct = createAction(TRANSFER_OWNER);
export const transferOwnerErrorAct = createAction(TRANSFER_OWNER_ERROR);
export const transferOwnerSuccessAct = createAction(TRANSFER_OWNER_SUCCESS);

export const resetAdmin = createAction(RESET_ADMIN);
