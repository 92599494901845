import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the eventDetail state domain
 */

const selectEventDetailDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by EventDetail
 */

const makeSelectEventDetail = () =>
  createSelector(selectEventDetailDomain, substate => substate);

const makeSelectEvent = () =>
  createSelector(selectEventDetailDomain, substate => substate.event);

const makeSelectEventStats = () =>
  createSelector(selectEventDetailDomain, substate => substate.eventDetail);

const makeSelectEventForms = () =>
  createSelector(selectEventDetailDomain, substate => substate.forms);

const makeSelectLoadingState = () =>
  createSelector(selectEventDetailDomain, substate => substate.loadingState);

const makeSelectStatsLoadingState = () =>
  createSelector(
    selectEventDetailDomain,
    substate => substate.eventDetailLoadingState,
  );

const makeSelectFormsLoadingState = () =>
  createSelector(
    selectEventDetailDomain,
    substate => substate.formsLoadingState,
  );

const makeSelectError = () =>
  createSelector(selectEventDetailDomain, substate => substate.error);

const makeSelectLinkFormState = () =>
  createSelector(selectEventDetailDomain, substate => substate.linkFormState);

const makeSelectBasicSettingState = () =>
  createSelector(
    selectEventDetailDomain,
    substate => substate.basicSettingState,
  );

const makeSelectBasicSetting = () =>
  createSelector(makeSelectEvent(), eventDetail =>
    eventDetail
      ? {
          maxTeamSize: eventDetail.maxTeamSize,
          supporterRegLink: eventDetail.supporterRegLink,
          teamHostingLink: eventDetail.teamHostingLink,
          supporterHostingLink: eventDetail.supporterHostingLink,
        }
      : {},
  );

const makeSelectConfirmedCounts = () =>
  createSelector(selectEventDetailDomain, state => ({
    confirmedTeamCount: state.confirmedTeamCount,
    confirmedMemberCount: state.confirmedMemberCount,
    confirmedVolunteerCount: state.confirmedVolunteerCount,
    confirmedSupporterCount: state.confirmedSupporterCount,
    confirmedCompanyTeam: state.confirmedCompanyTeam,
  }));

export default makeSelectEventDetail;
export {
  selectEventDetailDomain,
  makeSelectEvent,
  makeSelectLoadingState,
  makeSelectError,
  makeSelectEventStats,
  makeSelectEventForms,
  makeSelectStatsLoadingState,
  makeSelectFormsLoadingState,
  makeSelectBasicSettingState,
  makeSelectBasicSetting,
  makeSelectLinkFormState,
  makeSelectConfirmedCounts,
};
