/*
 *
 * InternalUser constants
 *
 */
export const REDUCER_KEY = 'InternalUser';
export const GET_INTERNAL_USER = 'app/InternalUser/GET_INTERNAL_USER';
export const GET_INTERNAL_USER_ERROR =
  'app/InternalUser/GET_INTERNAL_USER_ERROR';
export const GET_INTERNAL_USER_SUCCESS =
  'app/InternalUser/GET_INTERNAL_USER_SUCCESS';

export const GET_INTERNAL_USERS = 'app/InternalUser/GET_INTERNAL_USERS';
export const GET_INTERNAL_USERS_ERROR =
  'app/InternalUser/GET_INTERNAL_USERS_ERROR';
export const GET_INTERNAL_USERS_SUCCESS =
  'app/InternalUser/GET_INTERNAL_USERS_SUCCESS';

export const CHANGE_PASSWORD = 'app/InternalUser/CHANGE_PASSWORD';
export const CHANGE_PASSWORD_ERROR = 'app/InternalUser/CHANGE_PASSWORD_ERROR';
export const CHANGE_PASSWORD_SUCCESS =
  'app/InternalUser/CHANGE_PASSWORD_SUCCESS';

export const RESET_GET_INTERNAL_USER =
  'app/InternalUser/RESET_GET_INTERNAL_USER';
