import { call, put, takeLatest } from 'redux-saga/effects';

import { GET_EVENT_LIST } from './constants';
import { getEventListActionError, getEventListActionSuccess } from './actions';

import { getEventListApi } from '../../api/event.api';

function* fetchEvent({ payload }) {
  try {
    const { data } = yield call(getEventListApi, payload);
    if (data) {
      yield put(getEventListActionSuccess(data));
    } else {
      yield put(getEventListActionError({ message: 'Something went wrong' }));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getEventListActionError(errMessage));
  }
}

// Individual exports for testing
export default function* eventsPageSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_EVENT_LIST, fetchEvent);
}
