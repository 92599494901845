/*
 *
 * EventMemberList reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import membersEntityAdapter from './entity-adapter';
import {
  getEventMemberListAct,
  getEventMemberListErrorAct,
  getEventMemberListSuccessAct,
  getMemberTagsAct,
  getMemberTagsErrorAct,
  getMemberTagsSuccessAct,
  getUniqueCompaniesAct,
  getUniqueCompaniesErrorAct,
  getUniqueCompaniesSuccessAct,
  getUniqueCountriesAct,
  getUniqueCountriesErrorAct,
  getUniqueCountriesSuccessAct,
  getUniqueSourceAct,
  getUniqueSourceErrorAct,
  getUniqueSourceSuccessAct,
  getUniqueZipCodesAct,
  getUniqueZipCodesErrorAct,
  getUniqueZipCodesSuccessAct,
  resetMemberListAct,
  updateMemberDetailAct,
  updateMemberDetailErrorAct,
  updateMemberDetailSuccessAct,
} from './actions';

export const initialState = membersEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  zipCodesState: 0,
  zipCodes: [],
  tags: [],
  tagLoadingState: 0,
  countries: [],
  countryLoadingState: 0,
  companies: [],
  companyLoadingState: 0,
  sources: [],
  sourceLoadingState: 0,
});

/* eslint-disable default-case, no-param-reassign */
const eventMemberListSlice = createSlice({
  name: 'EventMemberList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getEventMemberListAct, state => {
        state.loadingState = 1;
        state.error = '';
        // membersEntityAdapter.removeAll(state);
      })
      .addCase(getEventMemberListErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getEventMemberListSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.count = payload.count;
        state.totalPages = payload.totalPages;
        membersEntityAdapter.setAll(state, payload.rows);
        state.totalPages = payload.totalPages;
      })
      .addCase(getMemberTagsAct, state => {
        state.tagLoadingState = 1;
        state.tagsError = '';
      })
      .addCase(getMemberTagsErrorAct, (state, { payload }) => {
        state.tagLoadingState = 3;
        state.tagsError = payload;
      })
      .addCase(getMemberTagsSuccessAct, (state, { payload }) => {
        state.tagLoadingState = 2;
        state.tagsError = '';
        state.tags = payload.rows;
      })
      .addCase(getUniqueZipCodesAct, state => {
        state.error = '';
        state.zipCodesState = 1;
      })
      .addCase(getUniqueZipCodesErrorAct, (state, { payload }) => {
        state.error = payload;
        state.zipCodesState = 3;
      })
      .addCase(getUniqueZipCodesSuccessAct, (state, { payload }) => {
        state.zipCodesState = 2;
        state.error = '';
        state.zipCodes = payload;
      })
      .addCase(getUniqueCompaniesAct, state => {
        state.error = '';
        state.companyLoadingState = 1;
      })
      .addCase(getUniqueCompaniesErrorAct, (state, { payload }) => {
        state.error = payload;
        state.companyLoadingState = 3;
      })
      .addCase(getUniqueCompaniesSuccessAct, (state, { payload }) => {
        state.companyLoadingState = 2;
        state.error = '';
        state.companies = payload;
      })
      .addCase(getUniqueCountriesAct, state => {
        state.error = '';
        state.countryLoadingState = 1;
      })
      .addCase(getUniqueCountriesErrorAct, (state, { payload }) => {
        state.error = payload;
        state.countryLoadingState = 3;
      })
      .addCase(getUniqueCountriesSuccessAct, (state, { payload }) => {
        state.countryLoadingState = 2;
        state.error = '';
        state.countries = payload;
      })
      .addCase(getUniqueSourceAct, state => {
        state.error = '';
        state.sourceLoadingState = 1;
      })
      .addCase(getUniqueSourceErrorAct, (state, { payload }) => {
        state.error = payload;
        state.sourceLoadingState = 3;
      })
      .addCase(getUniqueSourceSuccessAct, (state, { payload }) => {
        state.sourceLoadingState = 2;
        state.error = '';
        state.sources = payload;
      })
      .addCase(updateMemberDetailAct, (state, { payload }) => {
        const { id, data } = payload;
        state.error = '';
        membersEntityAdapter.updateOne(state, {
          id,
          changes: { loadingState: 1, ...data },
        });
      })
      .addCase(updateMemberDetailSuccessAct, (state, { payload }) => {
        const { id, boardingStatus } = payload;
        state.error = '';
        membersEntityAdapter.updateOne(state, {
          id,
          changes: {
            loadingState: 2,
            boardingStatus,
          },
        });
      })
      .addCase(updateMemberDetailErrorAct, (state, { payload }) => {
        const { id, error, oldValue } = payload;
        state.error = error;
        membersEntityAdapter.updateOne(state, {
          id,
          changes: { loadingState: 3, ...oldValue },
        });
      })
      .addCase(resetMemberListAct, () => initialState);
  },
});

export default eventMemberListSlice.reducer;
