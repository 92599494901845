/*
 *
 * EventMemberList constants
 *
 */
export const REDUCER_KEY = 'EventMemberList';

export const GET_EVENT_MEMBER_LIST =
  'app/EventMemberList/GET_EVENT_MEMBER_LIST';
export const GET_EVENT_MEMBER_LIST_ERROR =
  'app/EventMemberList/GET_EVENT_MEMBER_LIST_ERROR';
export const GET_EVENT_MEMBER_LIST_SUCCESS =
  'app/EventMemberList/GET_EVENT_MEMBER_LIST_SUCCESS';

export const GET_UNIQUE_ZIPCODES = 'app/EventMemberList/GET_UNIQUE_ZIPCODES';
export const GET_UNIQUE_ZIPCODES_ERROR =
  'app/EventMemberList/GET_UNIQUE_ZIPCODES_ERROR';
export const GET_UNIQUE_ZIPCODES_SUCCESS =
  'app/EventMemberList/GET_UNIQUE_ZIPCODES_SUCCESS';

export const GET_UNIQUE_COUNTRIES = 'app/EventMemberList/GET_UNIQUE_COUNTRIES';
export const GET_UNIQUE_COUNTRIES_ERROR =
  'app/EventMemberList/GET_UNIQUE_COUNTRIES_ERROR';
export const GET_UNIQUE_COUNTRIES_SUCCESS =
  'app/EventMemberList/GET_UNIQUE_COUNTRIES_SUCCESS';

export const GET_UNIQUE_COMPANY = 'app/EventMemberList/GET_UNIQUE_COMPANY';
export const GET_UNIQUE_COMPANY_ERROR =
  'app/EventMemberList/GET_UNIQUE_COMPANY_ERROR';
export const GET_UNIQUE_COMPANY_SUCCESS =
  'app/EventMemberList/GET_UNIQUE_COMPANY_SUCCESS';

export const GET_UNIQUE_SOURCE = 'app/EventMemberList/GET_UNIQUE_SOURCE';
export const GET_UNIQUE_SOURCE_ERROR =
  'app/EventMemberList/GET_UNIQUE_SOURCE_ERROR';
export const GET_UNIQUE_SOURCE_SUCCESS =
  'app/EventMemberList/GET_UNIQUE_SOURCE_SUCCESS';

export const GET_MEMBER_TAGS = 'app/EventMemberList/GET_MEMBER_TAGS';
export const GET_MEMBER_TAGS_ERROR =
  'app/EventMemberList/GET_MEMBER_TAGS_ERROR';
export const GET_MEMBER_TAGS_SUCCESS =
  'app/EventMemberList/GET_MEMBER_TAGS_SUCCESS';

export const UPDATE_MEMBER_DETAIL = 'app/EventMemberList/UPDATE_MEMBER_DETAIL';
export const UPDATE_MEMBER_DETAIL_ERROR =
  'app/EventMemberList/UPDATE_MEMBER_DETAIL_ERROR';
export const UPDATE_MEMBER_DETAIL_SUCCESS =
  'app/EventMemberList/UPDATE_MEMBER_DETAIL_SUCCESS';

export const RESET_GET_EVENT_MEMBER_LIST =
  'app/EventMemberList/RESET_GET_EVENT_MEMBER_LIST';
