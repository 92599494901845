/*
 *
 * MemberTags constants
 *
 */
export const REDUCER_KEY = 'MemberTags';
export const GET_MEMBER_TAGS_LIST = 'app/MemberTags/GET_MEMBER_TAGS_LIST';
export const GET_MEMBER_TAGS_LIST_ERROR =
  'app/MemberTags/GET_MEMBER_TAGS_LIST_ERROR';
export const GET_MEMBER_TAGS_LIST_SUCCESS =
  'app/MemberTags/GET_MEMBER_TAGS_LIST_SUCCESS';

export const DELETE_MEMBER_TAGS = 'app/MemberTags/DELETE_MEMBER_TAGS';
export const DELETE_MEMBER_TAGS_ERROR =
  'app/MemberTags/DELETE_MEMBER_TAGS_ERROR';
export const DELETE_MEMBER_TAGS_SUCCESS =
  'app/MemberTags/DELETE_MEMBER_TAGS_SUCCESS';

export const UPDATE_MEMBER_TAGS = 'app/MemberTags/UPDATE_MEMBER_TAGS';
export const UPDATE_MEMBER_TAGS_ERROR =
  'app/MemberTags/UPDATE_MEMBER_TAGS_ERROR';
export const UPDATE_MEMBER_TAGS_SUCCESS =
  'app/MemberTags/UPDATE_MEMBER_TAGS_SUCCESS';
export const SET_ACTIVE_TAGS_ITEM = 'app/MemberTags/SET_ACTIVE_TAGS_ITEM';

export const CREATE_MEMBER_TAGS = 'app/MemberTags/CREATE_MEMBER_TAGS';
export const CREATE_MEMBER_TAGS_ERROR =
  'app/MemberTags/CREATE_MEMBER_TAGS_ERROR';
export const CREATE_MEMBER_TAGS_SUCCESS =
  'app/MemberTags/CREATE_MEMBER_TAGS_SUCCESS';

export const RESET_GET_MEMBER_TAGS_LIST =
  'app/MemberTags/RESET_GET_MEMBER_TAGS_LIST';
