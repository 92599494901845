/*
 *
 * SyncJobList actions
 *
 */

import { createAction } from '@reduxjs/toolkit';
import {
  GET_JOB_LIST,
  GET_JOB_LIST_ERROR,
  GET_JOB_LIST_SUCCESS,
  RESET_JOB_LIST,
} from './constants';

export const getJobListAct = createAction(GET_JOB_LIST);
export const getJobListSuccessAct = createAction(GET_JOB_LIST_SUCCESS);
export const getJobListErrorAct = createAction(GET_JOB_LIST_ERROR);
export const resetJobListAct = createAction(RESET_JOB_LIST);
