/**
 * Combine all reducers in this file and export the combined reducers.
 */

import { combineReducers } from '@reduxjs/toolkit';
import { createRouterReducer } from '@lagunovsky/redux-react-router';

import AuthReducer from './managers/AuthManager/reducer';
import { REDUCER_KEY } from './managers/AuthManager/constants';

import history from './utils/history';

/**
 * Merges the main reducer with the router state and dynamically injected reducers
 */
export default function createReducer(injectedReducers = {}) {
  return combineReducers({
    router: createRouterReducer(history),
    [REDUCER_KEY]: AuthReducer,
    ...injectedReducers,
  });
}
