import { takeLatest, call, put } from 'redux-saga/effects';

import { loginApi } from '../../api/auth.api';

import { PERFORM_LOGIN } from './constants';

import { loginErrorAction, loginSuccessAction } from './actions';

import { setAuthAction } from '../AuthManager/actions';

function* loginAction({ payload }) {
  try {
    const { data } = yield call(loginApi, payload);
    if (data) {
      yield put(setAuthAction(data));
      yield put(loginSuccessAction(data));
    } else {
      yield put(loginErrorAction('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(loginErrorAction(errMessage));
  }
}

// Individual exports for testing
export default function* loginSaga() {
  //  uncomment this to setp default action
  yield takeLatest(PERFORM_LOGIN, loginAction);
}
