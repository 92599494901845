/* eslint-disable react/prop-types */
/**
 *
 * ModuleBaseRoute
 *
 */

import React from 'react';
import { Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Alert, AlertTitle, Paper, Typography } from '@mui/material';
import ErrorBoundary from '../ErrorBoundary';
import {
  makeSelectHasSupporterModule,
  makeSelectHasVolunteerModule,
} from '../../managers/AuthManager/selectors';

function NoAccessText() {
  return (
    <Paper elevation={2}>
      <Alert severity="error">
        <AlertTitle>Module disabled</AlertTitle>
        <Typography>You don&apos;t have access to this page.</Typography>
      </Alert>
    </Paper>
  );
}

export function ModuleBasedRoute({ module }) {
  const hasSupporterModule = useSelector(makeSelectHasSupporterModule());
  const hasVolunteerModule = useSelector(makeSelectHasVolunteerModule());

  if (
    (module === 'Volunteer' && !hasVolunteerModule) ||
    (module === 'Supporter' && !hasSupporterModule)
  ) {
    return <NoAccessText />;
  }

  return (
    <ErrorBoundary>
      <Outlet />
    </ErrorBoundary>
  );
}

export default ModuleBasedRoute;
