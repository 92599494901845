import { call, put, takeLatest } from 'redux-saga/effects';

import { GET_CURRENT_USER, PERFORM_LOGOUT } from './constants';

import { getCurrentUserApi } from '../../api/user.api';
import {
  getCurrentUserFailedAction,
  getCurrentUserSuccessAction,
  performLogoutSuccessAction,
} from './actions';
import { logoutApi } from '../../api/auth.api';

function* getCurrentUser() {
  try {
    const { data } = yield call(getCurrentUserApi);
    if (data) {
      yield put(getCurrentUserSuccessAction(data));
      // set token on storage
    } else {
      yield put(getCurrentUserFailedAction('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getCurrentUserFailedAction(errMessage));
  }
}

function* logoutUser() {
  try {
    const { data } = yield call(logoutApi);
    if (data) {
      yield put(performLogoutSuccessAction(data));
    }
  } catch (error) {
    // continure regardless of erro
  }
}

// Individual exports for testing
export default function* authSaga() {
  //  uncomment this to setp default action
  yield takeLatest(GET_CURRENT_USER, getCurrentUser);
  yield takeLatest(PERFORM_LOGOUT, logoutUser);
}
