/*
 * Login Messages
 *
 * This contains all the text for the Login container.
 */

import { defineMessages } from 'react-intl';

export const scope = 'app.containers.Login';

export default defineMessages({
  email: {
    id: `${scope}.email`,
    defaultMessage: 'Email',
  },
  password: {
    id: `${scope}.password`,
    defaultMessage: 'Password',
  },
  signin: {
    id: `${scope}.signin`,
    defaultMessage: 'Sign in',
  },
  forgotPwd: {
    id: `${scope}.forgotPwd`,
    defaultMessage: 'Forgot password?',
  },
  loginSuc: {
    id: `${scope}.loginSuc`,
    defaultMessage: 'Login Successful',
  },
});
