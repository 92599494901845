import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

import { selectById, selectAll } from '../entity-adapter';
import { formDisplayName } from '../../utils/common.util';

/**
 * Direct selector to the supporterDetail state domain
 */

const selectSupporterDetailDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by SupporterDetail
 */

const makeSelectSupporterDetail = () =>
  createSelector(selectSupporterDetailDomain, substate => substate);

const makeSelectLoadingState = () =>
  createSelector(
    selectSupporterDetailDomain,
    substate => substate.loadingState,
  );

const makeSelectUpdateState = () =>
  createSelector(selectSupporterDetailDomain, substate => substate.updateState);

const makeSelectDeleteState = () =>
  createSelector(selectSupporterDetailDomain, substate => substate.deleteState);

const makeSelectSupporter = id =>
  createSelector(
    selectSupporterDetailDomain,
    substate => selectById(substate, id) || {},
  );

const makeSelectSupporterContactDetail = id =>
  createSelector([makeSelectSupporter(id)], supporter => ({
    email: supporter.email,
    address: supporter.address,
    address1: supporter.address1,
    city: supporter.city,
    zip: supporter.zip,
    country: supporter.country,
    telephone: supporter.telephone,
    emergencyContactName: supporter.emergencyContactName,
    emergencyContactNumber: supporter.emergencyContactNumber,
  }));

const makeSelectSupporterAdditionalInfo = id =>
  createSelector([makeSelectSupporter(id)], supporter => ({
    registeredDate: supporter.registeredDate,
    gender: supporter.gender,
    dateOfBirth: supporter.dateOfBirth,
    walkLastSection: supporter.walkLastSection,
    consentImage: supporter.consentImage,
    boardingStatus: supporter.boardingStatus,
    hostingEntryId: supporter.hostingEntryId,
  }));

const makeSelectSupporterDisplayName = id =>
  createSelector([makeSelectSupporter(id)], supporter => {
    if (supporter.firstName || supporter.lastName) {
      return formDisplayName(supporter.firstName, '', supporter.lastName);
    }
    return '';
  });

const makeSelectSupporterTeamDetail = id =>
  createSelector([makeSelectSupporter(id)], supporter =>
    supporter ? supporter.team : {},
  );

const makeSelectFridayMeals = () =>
  createSelector(selectSupporterDetailDomain, substate =>
    selectAll(substate.fridayMeals),
  );

const makeSelectSaturdayMeals = () =>
  createSelector(selectSupporterDetailDomain, substate =>
    selectAll(substate.saturdayMeals),
  );

const makeSelectError = () =>
  createSelector(selectSupporterDetailDomain, substate => substate.error);

const makeSelectMealUpdateState = () =>
  createSelector(
    selectSupporterDetailDomain,
    substate => substate.mealUpdateState,
  );

const makeSelectMealAction = () =>
  createSelector(selectSupporterDetailDomain, substate => substate.mealAction);

export default makeSelectSupporterDetail;
export {
  selectSupporterDetailDomain,
  makeSelectLoadingState,
  makeSelectUpdateState,
  makeSelectSupporter,
  makeSelectError,
  makeSelectDeleteState,
  makeSelectMealUpdateState,
  makeSelectSupporterContactDetail,
  makeSelectSupporterAdditionalInfo,
  makeSelectFridayMeals,
  makeSelectSaturdayMeals,
  makeSelectSupporterDisplayName,
  makeSelectSupporterTeamDetail,
  makeSelectMealAction,
};
