import { call, put, takeEvery, takeLatest } from 'redux-saga/effects';

import {
  GET_EVENT,
  GET_EVENT_DETAIL,
  GET_LINK_FORMS,
  LINK_FORM,
  UPDATE_EVENT_BASIC_SETTING,
} from './constants';
import {
  getEventApi,
  getEventDetailApi,
  getEventLinkFormsApi,
  updateEventBasicSettingApi,
  updateLinkFormApi,
} from '../../api/event.api';
import {
  getEventDetailErrorAct,
  getEventDetailSuccessAct,
  getEventErrorAct,
  getEventSuccessAct,
  getLinkFormsErrorAct,
  getLinkFormsSuccessAct,
  linkFormError,
  linkFormSuccess,
  updateEventBasicSettingErrorAct,
  updateEventBasicSettingSuccessAct,
} from './actions';

function* fetchEvent({ payload }) {
  try {
    const { data } = yield call(getEventApi, payload);
    if (data) {
      yield put(getEventSuccessAct(data));
    } else {
      yield put(getEventErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getEventErrorAct(errMessage));
  }
}

function* fetchEventDetail({ payload }) {
  try {
    const { data } = yield call(getEventDetailApi, payload);
    if (data) {
      yield put(getEventDetailSuccessAct({ ...data, ob: payload.params?.ob }));
    } else {
      yield put(getEventDetailErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getEventDetailErrorAct(errMessage));
  }
}

function* fetchEventForms({ payload }) {
  try {
    const { data } = yield call(getEventLinkFormsApi, payload);
    if (data) {
      yield put(getLinkFormsSuccessAct(data));
    } else {
      yield put(getLinkFormsErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(getLinkFormsErrorAct(errMessage));
  }
}

function* updateFormLink({ payload }) {
  const { data: formData, oldValue } = payload;
  try {
    const { data } = yield call(updateLinkFormApi, payload);
    if (data) {
      yield put(
        linkFormSuccess({
          type: formData.type,
          id: data.id,
          title: data.title,
        }),
      );
    } else {
      yield put(
        linkFormError({
          type: formData.type,
          oldValue,
          error: 'Something went wrong',
        }),
      );
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(
      linkFormError({
        type: formData.type,
        error: errMessage,
        oldValue,
      }),
    );
  }
}
function* updateBasicSetting({ payload }) {
  try {
    const { data } = yield call(updateEventBasicSettingApi, payload);
    if (data) {
      yield put(updateEventBasicSettingSuccessAct(data));
    } else {
      yield put(updateEventBasicSettingErrorAct('Something went wrong'));
    }
  } catch (error) {
    const { response } = error;
    let errMessage = 'Something went wrong';
    if (response && response.data) {
      errMessage = response.data.message;
    }
    yield put(updateEventBasicSettingErrorAct(errMessage));
  }
}

// Individual exports for testing
export default function* eventDetailSaga() {
  yield takeLatest(GET_EVENT, fetchEvent);
  yield takeLatest(GET_EVENT_DETAIL, fetchEventDetail);
  yield takeEvery(GET_LINK_FORMS, fetchEventForms);
  yield takeEvery(LINK_FORM, updateFormLink);
  yield takeLatest(UPDATE_EVENT_BASIC_SETTING, updateBasicSetting);
}
