import TrailyApiInstance from './base';
const BASE_URL = '/users';

export const getUsersApi = async ({ params }) => {
  const response = await TrailyApiInstance.get(`${BASE_URL}`, { params });
  return response;
};
export const getCurrentUserApi = async () => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/user/current`);
  return response;
};

export const getUserDetailApi = async ({ id }) => {
  const response = await TrailyApiInstance.get(`${BASE_URL}/${id}`);
  return response;
};

export const getUsersAssignedToVolunteer = async id => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/assigned-to/volunteer/${id}`,
  );
  return response;
};

export const getUsersAssignedToTeam = async id => {
  const response = await TrailyApiInstance.get(
    `${BASE_URL}/assigned-to/team/${id}`,
  );
  return response;
};

export const updatePasswordApi = async ({ data }) => {
  const response = await TrailyApiInstance.post(
    `${BASE_URL}/change-password`,
    data,
  );
  return response;
};
