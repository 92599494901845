export const ThemeData = {
  components: {
    MUIDataTableHeadCell: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgba(97, 165, 52, 0.2)',
          color: '#3A3541',
        },
      },
    },
    MUIDataTableSelectCell: {
      styleOverrides: {
        headerCell: {
          backgroundColor: 'rgba(97, 165, 52, 0.2)',
          color: '#3A3541',
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          color: '#1976d2',
        },
        button: {
          color: '#1976d2',
        },
      },
    },
  },
  typography: {
    fontFamily: 'Manrope, Helvetica, Arial, sans-serif',
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        body: {
          fontFamily: 'Manrope, Helvetica, Arial, sans-serif',
          backgroundColor: '#F4F4F4',
        },
      },
    },
  },
  palette: {
    text: {
      primary: '#424447',
      secondary: '#7D7A7A',
    },
    success: {
      main: '#61A534',
      light: 'rgba(97, 165, 52, 0.10)',
      contrastText: 'rgba(255,255,255,1)',
    },
    error: {
      main: '#d32f2f',
      light: 'rgb(239 83 80 / 21%)',
    },
    background: {
      default: '#F4F4F4',
    },
    primary: {
      main: '#61A534',
      light: 'rgba(97, 165, 52, 0.2)',
      contrastText: 'rgba(255,255,255,1)',
    },
    secondary: {
      main: '#61A534',
      light: 'rgba(97, 165, 52, 0.2)',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary1: {
      main: '#ED874D',
      light: '#f9e5d9',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary2: {
      main: '#43bbc8',
      light: 'rgba(67, 187, 200, 0.3)',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary3: {
      main: '#EBC336',
      light: '#f9f1d4',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandSecondary4: {
      main: '#523790',
      light: '#dad5e6',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandLightGrey: {
      light: '#DDDDDD',
      main: '#7D7A7A',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandDarkGrey: {
      main: '#424447',
      light: '#DDDDDD',
      contrastText: 'rgba(255,255,255,1)',
    },
    brandColor: {
      main: '#61A534',
      light: '#D9F3C8',
      contrastText: 'rgba(255,255,255,1)',
      menuGradient:
        'linear-gradient(91.06deg, #61a5349c 0.24%, rgb(97 165 52 / 48%) 0.24%, rgba(97, 165, 52, 0) 104.26%)',
    },
  },
};
