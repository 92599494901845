import { createSelector } from '@reduxjs/toolkit';
import { initialState } from './reducer';
import { REDUCER_KEY } from './constants';

/**
 * Direct selector to the volunteerDetail state domain
 */

const selectVolunteerDetailDomain = state => state[REDUCER_KEY] || initialState;

/**
 * Other specific selectors
 */

/**
 * Default selector used by VolunteerDetail
 */

const makeSelectVolunteerDetail = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate);

const makeSelectVolunteer = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.volunteer);

const makeSelectError = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.error);

const makeSelectMissionState = () =>
  createSelector(
    selectVolunteerDetailDomain,
    substate => substate.missionState,
  );

const makeSelectLinkedState = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.linkedState);

const makeSelectTagState = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.tagState);

const makeSelectCanDriveState = () =>
  createSelector(
    selectVolunteerDetailDomain,
    substate => substate.canDriveState,
  );

const makeSelectUserAssign = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.userAssign);

const makeSelectGroupAssign = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.groupAssign);

const makeSelectVerifyState = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.verifyState);

const makeSelectLoadingState = () =>
  createSelector(
    selectVolunteerDetailDomain,
    substate => substate.loadingState,
  );

const makeSelectUpdateState = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.updateState);

const makeSelectDeleteState = () =>
  createSelector(selectVolunteerDetailDomain, substate => substate.deleteState);

const makeSelectParentalConsentState = () =>
  createSelector(
    selectVolunteerDetailDomain,
    substate => substate.parentalConsentState,
  );

const makeSelectLinkedVolunteerState = () =>
  createSelector(
    selectVolunteerDetailDomain,
    substate => substate.linkVolunteerState,
  );

export default makeSelectVolunteerDetail;
export {
  selectVolunteerDetailDomain,
  makeSelectVolunteer,
  makeSelectError,
  makeSelectLoadingState,
  makeSelectMissionState,
  makeSelectLinkedState,
  makeSelectTagState,
  makeSelectCanDriveState,
  makeSelectUserAssign,
  makeSelectGroupAssign,
  makeSelectVerifyState,
  makeSelectUpdateState,
  makeSelectParentalConsentState,
  makeSelectLinkedVolunteerState,
  makeSelectDeleteState,
};
