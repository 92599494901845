/*
 *
 * SupporterList constants
 *
 */
export const REDUCER_KEY = 'SupporterList';

export const GET_SUPPORTER_LIST = 'app/SupporterList/GET_SUPPORTER_LIST';
export const GET_SUPPORTER_LIST_ERROR =
  'app/SupporterList/GET_SUPPORTER_LIST_ERROR';
export const GET_SUPPORTER_LIST_SUCCESS =
  'app/SupporterList/GET_SUPPORTER_LIST_SUCCESS';

export const GET_UNIQUE_ZIPCODES = 'app/SupporterList/GET_UNIQUE_ZIPCODES';
export const GET_UNIQUE_ZIPCODES_ERROR =
  'app/SupporterList/GET_UNIQUE_ZIPCODES_ERROR';
export const GET_UNIQUE_ZIPCODES_SUCCESS =
  'app/SupporterList/GET_UNIQUE_ZIPCODES_SUCCESS';

export const GET_UNIQUE_COUNTRIES = 'app/SupporterList/GET_UNIQUE_COUNTRIES';
export const GET_UNIQUE_COUNTRIES_ERROR =
  'app/SupporterList/GET_UNIQUE_COUNTRIES_ERROR';
export const GET_UNIQUE_COUNTRIES_SUCCESS =
  'app/SupporterList/GET_UNIQUE_COUNTRIES_SUCCESS';

export const UPDATE_SUPPORTER = 'app/SupporterList/UPDATE_SUPPORTER';
export const UPDATE_SUPPORTER_ERROR =
  'app/SupporterList/UPDATE_SUPPORTER_ERROR';
export const UPDATE_SUPPORTER_SUCCESS =
  'app/SupporterList/UPDATE_SUPPORTER_SUCCESS';

export const RESET_GET_SUPPORTER_LIST =
  'app/SupporterList/RESET_GET_SUPPORTER_LIST';
