const GENERIC_MESSAGE = 'Something went wrong';

export const getErrorMessage = error => {
  let errorMessage = '';
  if (error) {
    const { response } = error;
    if (response && response.data) {
      errorMessage = response.data.message;
    } else if (error.message) {
      errorMessage = error.message;
    }
  }
  return errorMessage || GENERIC_MESSAGE;
};
