/**
 *
 * SplashScreen
 *
 */

import React, { memo } from 'react';
import { CircularProgress } from '@mui/material';

import Logo from '@images/logo-with-text.png';

export function SplashScreen() {
  return (
    <div
      style={{
        height: '100vh',
        width: '100vw',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
      }}
    >
      <img
        src={Logo}
        alt="Traily logo"
        style={{ maxWidth: '100%', padding: 24 }}
      />
      <CircularProgress />
    </div>
  );
}

export default memo(SplashScreen);
