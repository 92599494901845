/*
 *
 * SupporterList reducer
 *
 */
import { createSlice } from '@reduxjs/toolkit';

import supportersEntityAdapter from './entity-adapter';
import {
  getSupporterListAct,
  getSupporterListErrorAct,
  getSupporterListSuccessAct,
  getUniqueCountriesAct,
  getUniqueCountriesErrorAct,
  getUniqueCountriesSuccessAct,
  getUniqueZipCodesAct,
  getUniqueZipCodesErrorAct,
  getUniqueZipCodesSuccessAct,
  resetSupporterAct,
  updateSupporterDetailAct,
  updateSupporterDetailErrorAct,
  updateSupporterDetailSuccessAct,
} from './actions';

export const initialState = supportersEntityAdapter.getInitialState({
  loadingState: 0,
  error: '',
  totalPages: 0,
  count: 0,
  zipCodesState: 0,
  zipCodes: [],
  countries: [],
  countryLoadingState: 0,
});

/* eslint-disable default-case, no-param-reassign */
const supporterListSlice = createSlice({
  name: 'SupporterList',
  initialState,
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getSupporterListAct, state => {
        state.loadingState = 1;
        state.error = '';
        // supportersEntityAdapter.removeAll(state);
      })
      .addCase(getSupporterListErrorAct, (state, { payload }) => {
        state.loadingState = 3;
        state.error = payload;
      })
      .addCase(getSupporterListSuccessAct, (state, { payload }) => {
        state.loadingState = 2;
        state.error = '';
        state.count = payload.count;
        state.totalPages = payload.totalPages;
        supportersEntityAdapter.setAll(state, payload.rows);
        state.totalPages = payload.totalPages;
      })
      .addCase(getUniqueZipCodesAct, state => {
        state.error = '';
        state.zipCodesState = 1;
      })
      .addCase(getUniqueZipCodesErrorAct, (state, { payload }) => {
        state.error = payload;
        state.zipCodesState = 3;
      })
      .addCase(getUniqueZipCodesSuccessAct, (state, { payload }) => {
        state.zipCodesState = 2;
        state.error = '';
        state.zipCodes = payload;
      })

      .addCase(getUniqueCountriesAct, state => {
        state.error = '';
        state.countryLoadingState = 1;
      })
      .addCase(getUniqueCountriesErrorAct, (state, { payload }) => {
        state.error = payload;
        state.countryLoadingState = 3;
      })
      .addCase(getUniqueCountriesSuccessAct, (state, { payload }) => {
        state.countryLoadingState = 2;
        state.error = '';
        state.countries = payload;
      })
      .addCase(updateSupporterDetailAct, (state, { payload }) => {
        const { supporterId: id, data } = payload;
        state.error = '';
        supportersEntityAdapter.updateOne(state, {
          id,
          changes: { loadingState: 1, ...data },
        });
      })
      .addCase(updateSupporterDetailSuccessAct, (state, { payload }) => {
        const { id, data } = payload;
        state.error = '';
        supportersEntityAdapter.updateOne(state, {
          id,
          changes: {
            loadingState: 2,
            ...data,
          },
        });
      })
      .addCase(updateSupporterDetailErrorAct, (state, { payload }) => {
        const { supporterId: id, error, oldValue } = payload;
        state.error = error;
        supportersEntityAdapter.updateOne(state, {
          id,
          changes: { loadingState: 3, ...oldValue },
        });
      })
      .addCase(resetSupporterAct, () => initialState);
  },
});

export default supporterListSlice.reducer;
