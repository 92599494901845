/*
 *
 * EventTeamList constants
 *
 */
export const REDUCER_KEY = 'EventTeamList';
export const GET_EVENT_TEAM_LIST = 'app/EventTeamList/GET_EVENT_TEAM_LIST';
export const GET_EVENT_TEAM_LIST_ERROR =
  'app/EventTeamList/GET_EVENT_TEAM_LIST_ERROR';
export const GET_EVENT_TEAM_LIST_SUCCESS =
  'app/EventTeamList/GET_EVENT_TEAM_LIST_SUCCESS';

export const GET_RESPONSIBLE_USERS = 'app/EventTeamList/GET_RESPONSIBLE_USERS';
export const GET_RESPONSIBLE_USERS_ERROR =
  'app/EventTeamList/GET_RESPONSIBLE_USERS_ERROR';
export const GET_RESPONSIBLE_USERS_SUCCESS =
  'app/EventTeamList/GET_RESPONSIBLE_USERS_SUCCESS';

export const GET_UNIQUE_COMPANY_NAMES =
  'app/EventTeamList/GET_UNIQUE_COMPANY_NAMES';
export const GET_UNIQUE_COMPANY_NAMES_ERROR =
  'app/EventTeamList/GET_UNIQUE_COMPANY_NAMES_ERROR';
export const GET_UNIQUE_COMPANY_NAMES_SUCCESS =
  'app/EventTeamList/GET_UNIQUE_COMPANY_NAMES_SUCCESS';

export const GET_TEAM_TAGS = 'app/EventTeamList/GET_TEAM_TAGS';
export const GET_TEAM_TAGS_ERROR = 'app/EventTeamList/GET_TEAM_TAGS_ERROR';
export const GET_TEAM_TAGS_SUCCESS = 'app/EventTeamList/GET_TEAM_TAGS_SUCCESS';

export const UPDATE_TEAM_BOARDING_STATUS =
  'app/EventTeamList/UPDATE_TEAM_BOARDING_STATUS';
export const UPDATE_TEAM_BOARDING_STATUS_ERROR =
  'app/EventTeamList/UPDATE_TEAM_BOARDING_STATUS_ERROR';
export const UPDATE_TEAM_BOARDING_STATUS_SUCCESS =
  'app/EventTeamList/UPDATE_TEAM_BOARDING_STATUS_SUCCESS';

export const UPDATE_MEMBER_DETAIL = 'app/EventTeamList/UPDATE_MEMBER_DETAIL';
export const UPDATE_MEMBER_DETAIL_ERROR =
  'app/EventTeamList/UPDATE_MEMBER_DETAIL_ERROR';
export const UPDATE_MEMBER_DETAIL_SUCCESS =
  'app/EventTeamList/UPDATE_MEMBER_DETAIL_SUCCESS';

export const UPDATE_TEAM_NOTE = 'app/EventTeamList/UPDATE_TEAM_NOTE';
export const UPDATE_TEAM_NOTE_ERROR =
  'app/EventTeamList/UPDATE_TEAM_NOTE_ERROR';
export const UPDATE_TEAM_NOTE_SUCCESS =
  'app/EventTeamList/UPDATE_TEAM_NOTE_SUCCESS';

export const RESET_GET_EVENT_TEAM_LIST =
  'app/EventTeamList/RESET_GET_EVENT_TEAM_LIST';
