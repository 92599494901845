import axios from 'axios';
const BASE_URL = '/traily/api/auth';
export const loginApi = async payload => {
  const response = await axios.post(`${BASE_URL}/login`, payload);
  return response;
};

export const logoutApi = async () => {
  const response = await axios.get(`${BASE_URL}/logout`, {
    headers: {
      'X-Requested-With': 'XMLHttpRequest',
    },
  });
  return response;
};
