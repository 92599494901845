import { createEntityAdapter } from '@reduxjs/toolkit';

const adapter = createEntityAdapter({});

export const {
  selectById: selectTeamById,
  selectIds: selectTeamIds,
  selectAll: selectAllTeam,
} = adapter.getSelectors();

export default adapter;
