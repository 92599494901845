/*
 *
 * Auth constants
 *
 */
export const REDUCER_KEY = 'Auth';
export const SET_AUTH = 'app/Auth/set-auth';
export const GET_CURRENT_USER = 'app/Auth/get-current-user';
export const GET_CURRENT_USER_SUCCESS = 'app/Auth/get-current-user-success';
export const GET_CURRENT_USER_ERROR = 'app/Auth/get-current-user-error';
export const PERFORM_LOGOUT = 'app/Auth/perform-logout';
export const PERFORM_LOGOUT_SUCCESS = 'app/Auth/perform-logout-success';
export const SESSION_EXPIRED = 'app/Auth/session-expired';
export const SET_PAGE_TITLE = 'app/Auth/set-page-title';
export const UPDATE_VIEW_ROLE = 'app/Auth/update-view-role';
export const RESET_AUTH_STATE = 'app/Auth/reset-auth-state';
