import React, { memo } from 'react';
import { useSelector } from 'react-redux';
import { Box, Paper } from '@mui/material';
import { makeSelectEvent } from '../../managers/EventDetailManager/selectors';
import { DateBadge } from '../DateBadge';

export function ActiveEventInfo() {
  const eventDetail = useSelector(makeSelectEvent());
  if (eventDetail) {
    return (
      <Box
        flexGrow="1"
        justifyContent="center"
        display="flex"
        gap={1}
        alignItems="stretch"
      >
        {eventDetail.endDate ? (
          <DateBadge date={eventDetail.endDate}></DateBadge>
        ) : null}
        <Paper
          sx={{ p: 1, display: 'flex', alignItems: 'center' }}
          variant="outlined"
        >
          {eventDetail.name}
        </Paper>
      </Box>
    );
  }
  return null;
}

export default memo(ActiveEventInfo);
