import { createManager } from 'redux-injectors';

import reducer from './reducer';

import saga from './saga';

import { REDUCER_KEY } from './constants';

const EventsPageManager = createManager({
  name: 'EventsPageManager',
  key: REDUCER_KEY,
  reducer,
  saga,
});

export default EventsPageManager;
